import { BizFormPresenter } from '@src/bizForm/bizFormPresenter';
import {FormMode} from '@src/bizForm/formMode';
import { LifecycleEvent } from '@src/bizForm/lifecycleEvent';
import { ModuleNames } from '@src/bizForm/modules/interface';
import {RouteUtils} from '@src/router/routeUtils';

export const RefreshAction = {
  key: 'refresh',
  label: '刷新',
  options: {
    onClick: (presenter: BizFormPresenter, data: any) => {
      presenter.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.reloadBizForm);
    }
  }
};

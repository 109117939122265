import { IEntity, IEntityField } from "@src/interfaces/entity";
import { IQueryColumn, IQueryColumnItem, IQueryColumnKey } from "@src/interfaces/queryColumn";
import { TreeNodeProps } from "antd";
import { makeAutoObservable, toJS } from "mobx";

const _ = {
  set: require("lodash/set"),
};

export class QueryColumn {
  public id: string = "";

  public title: string;

  public items: IQueryColumnItem[] = [];

  public getItem(path: string): IQueryColumnItem {
    return this.items.find(item => item.path === path);
  }

  public getItems() {
    return this.items || [];
  }

  public setItems(data: IQueryColumnItem[]) {
    this.items = data;
  }

  constructor(public entityName: string) {
    makeAutoObservable(this);
  }

  public pathExist(path: string) {
    return this.items.some((item) => item.path === path);
  }

  //  通过AsyncTree添加新的item数据
  public addItemFormQueryColumn(data: TreeNodeProps) {
    const item: IQueryColumnItem = {
      path: data.key as string,
      displayName: [...data.ancestors.map((ancestor: IEntity) => ancestor.title), data.title].join('/'),
      isContextField: false,
      isDisabledSummary: false,
      isDisabledExport: false,
      visiable: true, // 默认显示
    };
    // 系统参数只能允许后台设置数据，前端不允许编辑
    if (data.fieldData.isSystemField) {
      // item.required = false;
    }
    this.items.push(item);
  }

  // 更新item的数据
  public updateQueryColumnItem(
    path: string,
    key: string,
    value: string | number | boolean
  ) {
    const index = this.items.findIndex((item) => item.path === path);
    if (index > -1) {
      this.items[index] = {
        ...this.items[index],
        [key]: value,
      };
    }
  }

  // 删除item
  public removeQueryColumnItem(path: string) {
    this.items = this.items.filter((item) => item.path !== path);
  }

  public toJSON() {
    return {
      id: this.id,
      title: this.title,
      entityName: this.entityName,
      items: this.getItems(),
    }
  }
}

export class QueryColumnUtils {
  static create(data: IQueryColumn) {
    const queryColumn = new QueryColumn(data.entityName);

    for (let key of Reflect.ownKeys(toJS(data))) {
      _.set(queryColumn, key, data[key as IQueryColumnKey]);
    }
    if (!queryColumn.items) {
      queryColumn.items = [];
    }

    return queryColumn;
  }

  static createObservableQueryColumn(entityName: string): QueryColumn {
    return makeAutoObservable(QueryColumnUtils.createQueryColumn(entityName));
  }

  static createQueryColumn(entityName: string): QueryColumn {
    return new QueryColumn(entityName);
  }

  static wrapperQueryColumn(data: IQueryColumn) {
    return makeAutoObservable(QueryColumnUtils.create(data));
  }
}

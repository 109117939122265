import {Input} from 'antd';
import * as React from 'react';
import {IBizFormComponentOProps} from '../interface';

interface IIextarea extends IBizFormComponentOProps {}

export const Textarea = (props: IIextarea) => {
  const {value = ''} = props;
  const handleBlur = (): void => {
    props?.onChange(value?.trim());
  };
  return <Input.TextArea rows={3} {...props} onBlur={handleBlur} />;
};

import { ENUM_Entity_Operator } from "@packages/gen/constants/enums/entityName";
import { OperatorFormPresenter, OperatorListPresenter } from "@src/pages/operator";
import { bizFormRoute } from "../bizFormRouter";
import { bizListRouter } from "../bizListRouter";

export default [
  ...bizFormRoute({
    entityName: ENUM_Entity_Operator,
    createPresenter: (options: any) => {
      // const { ProductFormPresenter } = await import(
      //   "@src/pages/product/productFormPresenter"
      // );
      // return new ProductFormPresenter(options);

      return new OperatorFormPresenter(options);
    },
  }),
  ...bizListRouter({
    entityName: ENUM_Entity_Operator,
    // @ts-ignore
    createPresenter: (options: any) => {
      return new OperatorFormPresenter(options);
    },
  })
];

import {IDisposer} from '@src/MST-Form/interface';
import {MSTForm} from '@src/MST-Form/MSTForm';
import { MSTFormField } from '@src/MST-Form/MSTFormField';
import {autobind} from 'core-decorators';
import {reaction} from 'mobx';
import {BizFormPresenter} from '../../bizForm/bizFormPresenter';

@autobind
export class ProductFormPresenter extends BizFormPresenter {
  public onFormCreated (form: MSTForm, disposers: IDisposer[]) {
    // disposers.push(
    //     reaction(
    //         () => form.select("category").value,
    //         (value) => {
    //             form.select("name").disabled = !form.select("name").disabled;
    //         }
    //     )
    // )
    // this.form.addValidator("name", (field: MSTFormField) => {
    //   if (field.value === "123") {
    //     return "123 ---";
    //   }
    //   return "";
    // })
  }

  private addValidators = (form: MSTForm) => {
    
  }
}

import {IAnyStateTreeNode, joinJsonPath, resolvePath, tryResolve} from 'mobx-state-tree';

const toString = Object.prototype.toString;

export const getNode = (target: IAnyStateTreeNode) => {
  return target.$treenode as any;
};

export const getNodeByPath = (target: IAnyStateTreeNode, path: string) => {
  if (!path) {
    return getNode(target);
  }
  const pathArr = path.split('.');
  let node = getNode(target);
  for (let i = 0; i < pathArr.length; i++) {
    if (toString.call(node) !== '[object Object]') {
      return undefined;
    }
    if (typeof node.getChildNode === 'function') {
      node = node.getChildNode(pathArr[ i ]);
    } else {
      return undefined;
    }
  }

  return node;
};

// initState 对应key
export const $FORM_STATE_KEY = '$FORM_STATE_KEY';
// MST path分隔符
export const MST_PATH_DIVIDER = '/';

export const $FORM_VALIDATE_KEY = '$FORM_VALIDATE_KEY';

import {DatePicker as AntDatePicker} from 'antd';
import moment from 'moment';
import {IBizFormComponentOProps} from '../interface';

const dateFormat = 'YYYY-MM-DD';

export interface IDataPicker extends IBizFormComponentOProps {}
/**
 *
 * @param props 日期组件
 * @returns
 */
export const DatePicker = (props: IDataPicker) => {
  const onChange = (date: any) => {
    props?.onChange(date ? date.valueOf() : undefined);
  };
  return <AntDatePicker value={moment(props.value, dateFormat)} onChange={onChange} disabled={props.disabled} />;
};

import { types } from "mobx-state-tree";

export type ReferSnapshotType = {
    id: string;
    [key: string]: any;
}

export type ReferType = ReferSnapshotType;

export const ReferPrimitive = types.custom<ReferSnapshotType, ReferType>({
  // Friendly name
  name: "refer",
  // given a serialized value and environment, how to turn it into the target type
  fromSnapshot(snapshot: ReferSnapshotType, env: any): ReferType {
    return snapshot;
  },
  // return the serialization of the current value
  toSnapshot(value: ReferType) {
    return value;
  },
  // if true, this is a converted value, if false, it's a snapshot
  isTargetType(value: ReferType | ReferSnapshotType) {
    return true;
  },
  // a non empty string is assumed to be a validation error
  getValidationMessage(snapshot: ReferSnapshotType): string {
    if (typeof snapshot === "object" && snapshot.hasOwnProperty("id")) {
      return "";
    }
    return `${JSON.stringify(snapshot)} is not a valid refer object`;
  },
});

/*
 * File: metadata copy.js
 * Project: biz-form
 * File Created: Thursday, 22nd July 2021 10:41:34 pm
 * Author: zxtang (1061225829@qq.com)
 * -----
 * Last Modified: Thursday, 22nd July 2021 10:41:34 pm
 * Modified By: zxtang (1061225829@qq.com>)
 * -----
 * Copyright 2017 - 2021 Your Company, Your Company
 */
import {http} from './http';

export function fetchQueryColumn (entityName: string): any {
  return http({
    url: `/queryColumn/${ entityName }`,
    method: 'get'
  });
}

export function createQueryColumn (data: any) {
  return http({
    url: '/queryColumn',
    method: 'post',
    data
  });
}

export function updateQueryColumn (data: any) {
  return http({
    url: '/queryColumn',
    method: 'put',
    data
  });
}

export function fetchQueryColumns () {
  return http({
    url: '/queryColumn/listByVersion',
    method: 'get'
  });
}

import * as React from "react";
import _get from "lodash/get";
import {RouteUtils} from "@src/router/routeUtils";
import {FormMode} from "@src/bizForm/formMode";

export interface IReferViewer {
  value: {id: string; name: string; [key: string]: any};
  referType?: string;
}
/**
 * 文本的查看模式内容组件
 */
export const ReferViewer = (props: IReferViewer): JSX.Element => {
  const handleLink = () => {
    RouteUtils.toForm({
      entityName: props.referType,
      id: props.value.id,
      mode: FormMode.View,
    });
  };

  return <a onClick={handleLink}>{_get(props.value, "name")}</a>;
};

import { IQueryColumn } from "@src/interfaces/queryColumn";
import { IQuerySchema } from "@src/interfaces/queryScheme";
import { metadata } from "@src/metadata/metadata";
import { store } from "@src/store/global";
import { FieldPathUtils } from "@src/utils/fieldPath";

// 查询列表值的key的前缀
const BIZ_LIST_QUERY_VALUE_PREFIX = 'BIZ_LIST_QUERY_VALUE_PREFIX';

export interface IBizListOptions {
    entityName: string;
    queryColumn: IQueryColumn;
    querySchema: IQuerySchema;
}

export interface BizColumn {
    path: string;
    title: string;
    bizType: string;
    isRefer?: boolean;
    enumType?: string;
    referType?: string;
}

export class BizListModel {

    constructor(private options: IBizListOptions) {
    }

    // table显示的列 
    public get columns(): Array<BizColumn> {
        const entity = metadata.getEntity(this.options.entityName);
        const columns: Array<BizColumn> = [];
        // 添加queryColumn的数据
        this.options.queryColumn.items.forEach(item => {
            if (item.visiable) {
                const entityField = entity.getField(item.path);
                const col: BizColumn = {
                    path: item.path,
                    title: item.displayName,
                    bizType: entityField ? entityField.bizType : '',
                    isRefer: entityField ? entityField.isRefer : false,
                };
                if (entityField) {
                    if (entityField.isEnum) {
                        col.enumType = entityField.enumType
                    }
                    if (entityField.isRefer) {
                        col.referType = entityField.referType;
                    }
                }
                columns.push(col)
            }
        })
        // 补充id
        if (!columns.some(col => col.path === 'id')) {
            columns.unshift({
                path: 'id',
                title: 'ID',
                bizType: "text"
            })
        }

        return columns
    }

    // 查询时的字段
    public get queryColumns(): Array<string> {
        const columnPath: Array<string> = [];
        // 添加queryColumn的数据
        this.options.queryColumn.items.forEach(item => {
            columnPath.push(item.path)
        })

        return columnPath;
    }

    // 查询条件
    public get querySchemaItems() {
        return this.options.querySchema.items;
    }

    public get queryValue() {
        const valuesMap = store.getItem(`${BIZ_LIST_QUERY_VALUE_PREFIX}_${this.options.entityName}`) || {};
        return valuesMap;
    }
}
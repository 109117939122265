import {CloudDownloadOutlined} from "@ant-design/icons";
import OperatorStore from "@src/operator";
import {RouteUtils} from "@src/router/routeUtils";
import {Downloadutils} from "@src/utils/downloadUtils";
import {Avatar, Button, Dropdown, Menu} from "antd";
import * as React from "react";
import styles from "./style.module.scss";

const downloadMenu = (
  <Menu
    items={[
      {
        key: "1",
        label: (
          <a
            rel="noopener noreferrer"
            href="javascript:;"
            onClick={() => Downloadutils.download("https://extension.vwood.xyz/workflow.crx", "workflow.crx")}
          >
            crx
          </a>
        ),
      },
      {
        key: "2",
        label: (
          <a
            rel="noopener noreferrer"
            href="javascript:;"
            onClick={() => Downloadutils.download("https://extension.vwood.xyz/workflow.zip", "workflow.zip")}
          >
            zip
          </a>
        ),
      },
    ]}
  />
);

export default class Header extends React.PureComponent {
  handleLogout = () => {
    OperatorStore.logout();
  };

  handleSetting = () => {
    RouteUtils.to("/settings/changePassword");
  };

  renderUserSetting = () => {
    return (
      <div>
        <Dropdown overlay={downloadMenu} placement="bottom">
          <Button className={styles["layout-header-extension"]} shape="round" icon={<CloudDownloadOutlined />}>
            下载插件
          </Button>
        </Dropdown>
        <Dropdown
          placement="bottomRight"
          overlay={
            <Menu>
              <Menu.Item>
                <a onClick={this.handleSetting}>设置</a>
              </Menu.Item>
              <Menu.Item>
                <a onClick={this.handleLogout}>退出</a>
              </Menu.Item>
            </Menu>
          }
        >
          <Avatar size={32} src={OperatorStore.avatar()}></Avatar>
        </Dropdown>
      </div>
    );
  };
  render() {
    return (
      <div className={styles["layout-header"]}>
        <div className={styles["layout-header-left"]}></div>
        <div className={styles["layout-header-right"]}>{this.renderUserSetting()}</div>
      </div>
    );
  }
}

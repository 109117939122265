/*
 * File: metadata copy.js
 * Project: biz-form
 * File Created: Thursday, 22nd July 2021 10:41:34 pm
 * Author: zxtang (1061225829@qq.com)
 * -----
 * Last Modified: Thursday, 22nd July 2021 10:41:34 pm
 * Modified By: zxtang (1061225829@qq.com>)
 * -----
 * Copyright 2017 - 2021 Your Company, Your Company
 */
import {http} from './http';

export function asyncChangePassword (data: {oldPassword: String; newPassword: string}): any {
  return http({
    url: '/Operator/changePassword',
    method: 'post',
    data
  });
}

export function asyncFetchUpyunIno (): Promise<any> {
  return http({
    url: 'Operator/upyunInfo',
    method: 'post'
  });
}

export function asyncUpdateAvatar (data: {avatar: string}): Promise<any> {
  return http({
    url: '/Operator/avatar',
    method: 'put',
    data
  });
}

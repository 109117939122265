import { BizListPresenter } from "@src/bizList/bizListPresenter";
// import { ListConfig } from "@src/bizList/interface";


export class FileListPresenter extends BizListPresenter {

    // public listConfig(): ListConfig {
    //     return {
    //         "name": {
    //             cellRender: (val) => {
    //                 return <span style={{color: 'red'}}>{val}</span>
    //             }
    //         }
    //     }
    // }
    
}
import {getSnapshot, applySnapshot, resolvePath, unprotect, IAnyStateTreeNode} from 'mobx-state-tree';
import {$FORM_VALIDATE_KEY, getNode, getNodeByPath} from './MSTForm-utils';
import {MSTFormField, MSTFormFieldParams} from './MSTFormField';
import {computed, makeAutoObservable, observable, reaction, toJS} from 'mobx';
import {MSTFieldWriteableProps, Optional} from './interface';
import lodashGet from 'lodash/get';
import {IValidators, Validators} from './validators/validator';
import {IValidator} from './validators/interface';

export interface FieldOptions {
  initState: Optional<MSTFieldWriteableProps>;
}

export interface MSTFormOptions {
  fieldOptions?: {[fieldName: string]: FieldOptions};
}

export class MSTForm<T = {}> {
  static create<T> (mstInstance: T, options: MSTFormOptions) {
    return new MSTForm(mstInstance, options);
  }

  private initSnapshot: T; // 快照

  private fieldsMap: Map<string, MSTFormField> = new Map();

  public valiator: Validators;

  constructor (public mstInstance: IAnyStateTreeNode, private options: MSTFormOptions) {
    this.initSnapshot = getSnapshot(this.mstInstance);
    unprotect(this.mstInstance);
    this.initState();
    this.initValidators();
  }

  destructor () {}

  initState () {
    const rootNode = getNode(this.mstInstance);
    rootNode[ '$FORM_STATE_KEY' ] = observable.map(new Map<string, any>());
  }

  //初始化校验器
  initValidators () {
    const {fieldOptions = {}} = this.options;

    const params: IValidators = {
      form: this,
      options: {
        validators: {}
      }
    };

    (Reflect.ownKeys(fieldOptions) as Array<string>).forEach((path) => {
      params.options.validators[ path ] = lodashGet(fieldOptions[ path ], 'validators', []);
    });

    this.valiator = new Validators(params);
  }

  select (path: string): MSTFormField {
    const node = getNodeByPath(this.mstInstance, path);
    if (!node) {
      return null;
    }
    // const nodeId = node.nodeId; // 使用nodeId作为map的可以，防止模型在嵌套情况下时，path相同造成的数据覆盖

    // 1: 从缓存中获取
    if (this.fieldsMap.has(path)) {
      return this.fieldsMap.get(path);
    }

    const fieldOptions = lodashGet(this.options.fieldOptions, path, {}) as FieldOptions;
    const initSnapshot = lodashGet(this.initSnapshot, path);
    const options = {
      ...fieldOptions
    };

    const params: MSTFormFieldParams = {
      path: path, // 目前不考虑嵌套的path情况，第一版处理最简单的逻辑
      form: this as any,
      node: node,
      options,
      initSnapshot
    };

    const field = new MSTFormField(params);

    this.fieldsMap.set(path, field);

    return field;
  }

  public getValue (path?: string): any {
    if (!path) {
      return this.mstInstance;
    }
    return this.mstInstance.getValue(path);
  }

  public setValue (path: string, value: any) {
    // resolvePath(this.mstInstance, path);
    // (this.mstInstance as any)[path] = value;
    this.mstInstance.setValue(path, value);
  }
  // 获取数据
  public getFormData () {
    return this.mstInstance.toJSON();
  }
  // 获取修改过的数据
  public getChangeData () {
    const result: any = {};
    [ ...this.fieldsMap.keys() ].map((key: string) => {
      if (this.fieldsMap.get(key).changed) {
        result[ key ] = this.fieldsMap.get(key).value;
      }
    });
    return result;
  }
  // 重置数据
  public reset () {
    applySnapshot(this.mstInstance, this.initSnapshot);
  }

  public validate () {
    const result = this.valiator.validate();
    return result;
  }

  public addValidator (path: string, validator: IValidator) {
    return this.valiator.addValidator(path, validator);
  }

  public removeValidator (path: string, validator: IValidator) {
    this.valiator.removeValidator(path, validator);
  }
}

import { observer } from 'mobx-react';
import * as React from 'react';
import ListSolution from './ListSolution';
import { ListSolutionPresenter } from './listSolutionPresenter';
import { QuerySchemaList } from './QuerySchemaList';

export interface IQueryListSolution {
  presenter: ListSolutionPresenter;
}

export interface SQueryListSolution {
  showSetting: boolean;
}

export class QueryListSolution extends React.Component<IQueryListSolution, SQueryListSolution> {


  constructor(props: IQueryListSolution) {
    super(props);

    this.state = {
      showSetting: false,
    }
  }

  private submit = () => {

  }

  render() {
    return (
      <div>
        <QuerySchemaList
          entityName={this.props.presenter.entityName}
          querySchemaItems={this.props.presenter.querySchemaItems}
          querySolution={this.props.presenter.querySolution}
        />
        {/* <ListSolution presenter={this.props.presenter}/> */}
      </div>
    );
  }
}

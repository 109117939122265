import React, {Suspense} from 'react';
import {ConfigProvider, Layout, Spin} from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import "./common/style/vars.scss";
import './App.css';

import {Nav} from './Layout/Nav';
import AppRouter from './router';
import {Router, Route, Switch} from 'react-router-dom';
// import { Router, Route } from "react-router";
import {Modals} from './components/Modals';
import {InitDataLoader} from './components/InitDataLoader';
import {globalHistory} from './router/routeUtils';
import NoSiderRoutes from './router/noSiderRoutes';
import AuthCheck from './components/AuthCheck';
import Header from './Layout/header';
const {Content} = Layout;

function App () {
  return (
    <ConfigProvider locale={zhCN}>
      <Layout style={{height: '100vh', width: '100vw'}}>
        <Router history={globalHistory}>
          <Suspense fallback={<Spin />}>
            <Switch>
              {NoSiderRoutes}
              <AuthCheck>
                <InitDataLoader>
                  <Route>
                    <Layout>
                      <Nav />
                      <Layout className="site-layout">
                        <Header></Header>
                        <Content className="layout-content">
                          <AppRouter/>
                        </Content>
                      </Layout>
                    </Layout>
                  </Route>
                </InitDataLoader>
              </AuthCheck>
            </Switch>
          </Suspense>
        </Router>
        <Modals />
      </Layout>
    </ConfigProvider>
  );
}

export default App;

import {Button, Spin} from 'antd';
import * as React from 'react';
import style from './style.module.scss';

export enum LoadingStatus {
  Complete = 'Complete',
  Failed = 'Failed',
  Loading = 'Loading',
  None = 'None',
}

interface ILoadingContainer {
  // loading: boolean;
  children: () => JSX.Element;
  status: LoadingStatus;
}

export default function LoadingContainer(props: ILoadingContainer) {
  const {status, children} = props;
  switch (status) {
    case LoadingStatus.Complete:
      return children && React.Children.only(children());
    case LoadingStatus.Loading:
      return <Spin className={style['loading-container']}></Spin>;
    case LoadingStatus.Failed:
      return (
        <div>
          <span>系统发生错误</span>&nbsp;&nbsp;
          <Button size="small" type="primary" onClick={() => window.location.reload()}>
            请重试
          </Button>
        </div>
      );
    default:
      return null;
  }
}

import {Select} from 'antd';
import React, {memo} from 'react';
import {BoolSelectProps} from './types';
import './style.scss';

export default memo(function BoolSelect (props: BoolSelectProps): JSX.Element {
  const {isMutil, ...reset} = props;
  const options: Array<{label: string; value: any}> = [
    {
      label: '是',
      value: true
    },
    {
      label: '否',
      value: false
    }
  ];
  return <Select className="bool-select" options={options} {...reset} allowClear/>;
});

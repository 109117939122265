/**
 * 元数据管理
 */

import {Entity} from "./entity";
import {Enum} from "./enum";
import {metadataBase} from "./metadataBase";

export class Metadata {
  constructor() {}

  public init({descriptions}: any) {
    metadataBase.init({descriptions});
  }

  public getEntity(entityName: string) {
    return metadataBase.getEntity(entityName);
  }

  public getRefer(entityName: string, referName: string) {
    return metadataBase.getReferConfig(entityName, referName);
  }

  public getList(): Array<Entity> {
    return metadataBase.getList();
  }
  public getEnum(enumName: string): Enum {
    return metadataBase.getEnum(enumName);
  }
  public getEnumOptions(enumName: string): Array<{
    label: string;
    value: string | number | boolean;
  }> {
    return metadataBase.getEnumOptions(enumName);
  }
}

const metadataInstance = new Metadata();

window.metadata = metadataInstance;
export const metadata = metadataInstance;

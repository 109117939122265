import {metadata} from '@src/metadata/metadata';
import {Select} from 'antd';
import React, {memo} from 'react';
import {EnumSelectProps} from './types';
import './style.scss';

export default memo(function EnumSelect (props: EnumSelectProps): JSX.Element {
  const {enumName, isMutil, ...reset} = props;
  const options: Array<{label: string; value: any}> =
    metadata.getEnum(props.enumName)?.values.map((val) => ({
      label: val.title,
      value: val.value
    })) || [];

  // Mode = 'multiple' | 'tags' | 'combobox';
  const mode = isMutil ? 'multiple' : undefined;
  return <Select className="enum-select" options={options} mode={mode} {...reset} />;
});

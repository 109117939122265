import {FormMode} from '@src/bizForm/formMode';
import {Modals} from '@src/components/Modals';
import {createBrowserHistory} from 'history';

//全局使用的history
export const globalHistory = createBrowserHistory();

const unlisten = globalHistory.listen((data: any) => {
  console.log(data);
});

export interface IRouteFormOptions {
  entityName: string;
  mode: FormMode;
  id?: string;
}

export interface IRouteListOptions {
  entityName: string;
  force?: boolean;
}

export class RouteUtils {
  // 跳转到另一个表单的路由
  static toForm (options: IRouteFormOptions) {
    const {entityName, mode, id} = options;
    const to = `/${ entityName }/${ mode }/${ id ? id : '' }`;
    RouteUtils.to(to);
  }

  static to (url: string) {
    Modals.clear();
    globalHistory.push(url, {
      _time: Date.now()
    });
  }

  // 返回列表页
  static toList (options: IRouteListOptions) {
    const {entityName} = options;
    const to = `/${ entityName }-list`;
    RouteUtils.to(to);
  }
}

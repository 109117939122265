import {autobind} from 'core-decorators';
import {FieldOptions, MSTForm} from '@src/MST-Form/MSTForm';
import {MSTFormHelper, MSTFormHelperOptions} from '@src/MST-Form/MSTFormHepler';
import {Metadata} from '@src/pages/devtool/bizForm/form/metadata';
import {Template} from '@src/pages/devtool/bizForm/form/template';
import {BizForm} from './core/model/bizForm';
import {FormMode} from './formMode';
import {LifecycleEvent} from './lifecycleEvent';
import {EventModule} from './modules';
import {InitModule} from './modules/initiModule';
import {ModuleNames} from './modules/interface';
import {ModuleManager} from './modules/moduleManager';
import {LoadingStatus} from '@src/components/LoadingContainer';
import {FormModule} from './modules/formModule';
import {RouteUtils} from '@src/router/routeUtils';
import {observable} from 'mobx';
import {IDisposer} from '@src/MST-Form/interface';
import {v4 as uuidv4} from 'uuid';
import {BizFormConfig} from './interface';
import {BizFormActionManager} from '@src/bizAction/bizFormActionManager';
import {BizFormItemPresenter} from './bizFormItemPresenter';

export interface IBizFormPresenterOptions {
  entityName: string;
  mode: FormMode;
}

@autobind
export class BizFormPresenter<T = any> {
  // 用于表示form表单的id，用于后面获取form元素使用
  public _formId = uuidv4();

  public id?: string;

  // 表单模式
  public mode: FormMode;

  // 表示的前端模型
  public model: BizForm;

  // 元数据
  public metadata: Metadata;

  // 模板数据
  public template: Template;

  public entityName: string;

  // 最原始的表单数据
  public initFormData: any = {};

  public disposers: Array<IDisposer> = [];

  @observable
  public form: MSTForm;

  @observable
  public loadingStatus: LoadingStatus = LoadingStatus.None;

  // 模块管理属性
  public modules: ModuleManager;

  public config: BizFormConfig<T>;
  // 明细表presenter
  public bizFormItemPresenters: Array<BizFormItemPresenter> = [];

  public getModule (moduleName: string) {
    return this.modules.get(moduleName);
  }

  // 获取所有master的字段属性的数组, logicPath 字段的所属路径前缀
  public getFields (logicPath: string) {
    return this.model.fields.filter((field) => field.path.startsWith(logicPath));
  }

  // 表单数据
  // public formData: BizFormData = new BizFormData();

  constructor (public readonly options: IBizFormPresenterOptions) {
    this.entityName = options.entityName;
    this.mode = options.mode;
  }

  // 析构函数
  public destructor () {
    // 销毁当前模块添加的监听器
    this.disposers.forEach((disposer) => disposer && disposer());
  }

  public initData () {
    this.initModules();
    this.getModule(ModuleNames.EventModule).on(LifecycleEvent.onLoadedInitData, this.initForm);
    this.getModule(ModuleNames.EventModule).on(LifecycleEvent.afterSave, this.afterSave);
    this.getModule(ModuleNames.EventModule).on(LifecycleEvent.onFormCreated, this.$onFormCreated);
    this.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.onModuleReady);

    this.config = this._bizFormConfig();
  }

  private initModules () {
    this.modules = new ModuleManager({
      seeds: {
        [ ModuleNames.Presenter ]: this
      },
      modules: [ EventModule, InitModule, FormModule ],
      presenter: this
    });
  }

  // // 初始化MSTForm
  public initForm () {
    this.model = new BizForm(this.entityName, this.metadata, this.template);
    const options: MSTFormHelperOptions = {
      fields: [],
      // TODO: 暂时将id省略掉
      //   fields: ["id"],
      fieldOptions: {},
      initValue: this.initFormData
    };
    this.model.fields
      .map((field) => field.path)
      .forEach((fieldName) => {
        // @TODO 长度超过一要么是携带、要么是子表。目前不处理子表
        if (fieldName.split('.').length === 1) {
          options.fields.push(fieldName);
        } else {
          // 将所属业务对象加进去，取值时才不会从对应的业务对象上拿到具体的值，否则会因为找不到对象报错
          options.fields.push(fieldName.split('.')[ 0 ]);
        }
      });
    options.fields = [ ...new Set(options.fields) ];

    // 主表的所有属性的initState
    options.fields
      .filter((fieldName) => this.model.entity.getField(fieldName))
      .forEach((fieldName) => {
        if (!options.fieldOptions[ fieldName ]) {
          options.fieldOptions[ fieldName ] = {} as FieldOptions;
        }
        if (!options.fieldOptions[ fieldName ].initState) {
          options.fieldOptions[ fieldName ].initState = {};
        }

        options.fieldOptions[ fieldName ].initState = {
          disabled: this.model.fieldsMap[ fieldName ].readonly,
          required: this.model.fieldsMap[ fieldName ].required,
          errors: []
        };

        // 必填校验
        // options.fieldOptions
      });
    // 携带字段的

    this.form = MSTFormHelper.createForm(this.entityName, options);

    MSTFormHelper.addRequiredValidatror(this.form, options.fields, this.model);
    window[ 'form' ] = this.form;

    this.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.onFormInitialized);
    this.loadingStatus = LoadingStatus.Complete;
  }

  private afterSave (id: string) {
    RouteUtils.toForm({
      entityName: this.entityName,
      mode: FormMode.View,
      id
    });
  }

  //<! ---- start 生命周期>
  public onBeforeSave (data: any) {
    return data;
  }

  // 表单渲染完成
  public onFormCreated (form: MSTForm, disposers: IDisposer[]) {}
  //<! ---- end 生命周期>

  public save () {
    this.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.onSave);
  }

  public getForm () {
    return this.form;
  }

  public $onFormCreated () {
    this.onFormCreated(this.form, this.disposers);
  }

  public bizFormConfig (config: BizFormConfig<T>): BizFormConfig<T> {
    // const listConfig = new ListConfig();
    return config;
  }

  private _bizFormConfig (): BizFormConfig<T> {
    const defaultConfig: BizFormConfig<T> = {
      formActionManager: new BizFormActionManager()
    };
    return this.bizFormConfig(defaultConfig);
  }
}

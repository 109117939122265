import { IEntity } from "@src/interfaces/entity";
import { IQuerySchema, IQuerySchemaItem, IQuerySchemaKey } from "@src/interfaces/queryScheme";
import { TreeNodeProps } from "antd";
import { makeAutoObservable, toJS } from "mobx";
import { v4 as uuidv4 } from "uuid";

const _ = {
  set: require("lodash/set"),
};

export class QuerySchema {
  public id?: string;
  public title: string;
  public items: Array<IQuerySchemaItem> = [];

  public paths() {
    return this.items.map(item => item.path);
  }

  constructor(public entityName: string) {
    makeAutoObservable(this);
  }

  public getField(path: string): IQuerySchemaItem {
    return this.items.find((col) => col.path === path);
  }

  public getItems() {
    return this.items || [];
  }

  public setItems(data: IQuerySchemaItem[]) {
    this.items = data || [];
  }


  public pathExist(path: string) {
    return this.items.some((col) => col.path === path);
  }

  //  通过AsyncTree添加新的item数据
  public addItemFormQuerySchame(item: IQuerySchemaItem) {
    const newItem: IQuerySchemaItem = {
      ...item,
    };
    this.items.push(newItem);
  }

  //  通过AsyncTree添加新的item数据
  public addItemFormQuerySchema(data: TreeNodeProps) {
    const item: IQuerySchemaItem = {
      path: data.key as string,
      displayName: [...data.ancestors.map((ancestor: IEntity) => ancestor.title), data.title].join('/'),
      isContextField: false,
      isDisabledSummary: false,
      isDisabledExport: false,
      valueType: '',
      isQuickQuery: false,
    };
    // 系统参数只能允许后台设置数据，前端不允许编辑
    if (data.fieldData.isSystemField) {
      // item.required = false;
    }
    this.items.push(item);
  }

  // 更新item的数据
  public updateQuerySchemaItem(
    path: string,
    key: string,
    value: string | number | boolean
  ) {
    const index = this.items.findIndex((item) => item.path === path);
    if (index > -1) {
      this.items[index] = {
        ...this.items[index],
        [key]: value,
      };
    }
  }

  // 删除item
  public removeQuerySchemaItem(path: string) {
    if (this.items.some((item) => item.path === path)) {
      this.items = this.items.filter((item) => item.path !== path);
    }
  }

  public updateItems = (items: IQuerySchemaItem[]) => {
    this.items = items || [];
  };


  public toJSON() {
    return {
      id: this.id,
      title: this.title,
      entityName: this.entityName,
      items: this.getItems(),
    }
  }
}

export class QuerySchemaUtils {

  static create(data: IQuerySchema) {
    const querySchema = new QuerySchema(data.entityName);
    for (let key of Reflect.ownKeys(toJS(data))) {
      _.set(querySchema, key, data[key as IQuerySchemaKey]);
    }
    if (!querySchema.items) {
      querySchema.items = [];
    }
    return querySchema;
  }

  static createObservableQuerySchema(entityName: string): QuerySchema {
    return new QuerySchema(entityName);
  }

  static wrapperQuerySchema(data: IQuerySchema) {
    return makeAutoObservable(QuerySchemaUtils.create(data));
  }
}

import React, { FC, memo, useCallback, useEffect, useRef } from "react";
import { CodeEditorProps } from "./types";
// @ts-ignore
import { EditorView, basicSetup } from "codemirror";
import { javascript as langJS } from "@codemirror/lang-javascript";
import { css as langCSS } from "@codemirror/lang-css";
import { html as langHtml } from "@codemirror/lang-html";
import { markdown as langMarkdown } from "@codemirror/lang-markdown";
import style from "./style.module.scss";
import { Compartment, EditorState } from "@codemirror/state";
import { oneDark } from "@codemirror/theme-one-dark";
import {
  drawSelection,
  keymap,
  lineNumbers,
  ViewUpdate,
} from "@codemirror/view";
import { defaultKeymap, historyKeymap, history } from "@codemirror/commands";

const editorTheme = new Compartment();
const darkTheme = editorTheme.of(oneDark)
// react-codemirror 地址
// https://github.com/uiwjs/react-codemirror/blob/master/core/src/useCodeMirror.ts


const LangMap = {
  javascript: langJS,
  css: langCSS,
  html: langHtml,
  markdown: langMarkdown,
}

const CodeEditorBase: FC<CodeEditorProps> = (props): JSX.Element => {
  const codeRef = useRef<HTMLDivElement>();

  const handleChange = useCallback((vu: ViewUpdate) => {
    if (vu.docChanged && typeof props.onChange === "function") {
      const doc = vu.state.doc;
      const value = doc.toString();
      props.onChange(value);
    }
  }, [props.onChange]);

  useEffect(() => {

    const lang = LangMap[props.lang || "html"]

    const extensions = [
      basicSetup,
      lang(),
      darkTheme,
      history(),
      drawSelection(),
      lineNumbers(),
      keymap.of([
        ...defaultKeymap,
        ...historyKeymap,
        {
          key: "Mod-s",
          mac: "Mod-s",
          run: () => {
            // 出发保存行为
            props.onChange?.(editor.state.doc.toString());
          },
        },
      ] as any),
      EditorView.updateListener.of(handleChange),
    ];
    if (props.readonly) {
      extensions.push(EditorState.readOnly.of(true));
    }

    const editor = new EditorView({
      doc: props.value,
      extensions: extensions,
      parent: codeRef.current,
    });

  }, []);

  return <div ref={codeRef} className={style["code-editor"]}></div>;
};

export default memo(CodeEditorBase);

import {autobind} from "core-decorators";
import {LifecycleEvent} from "../lifecycleEvent";
import {BaseModule} from "./baseModule";
import {EventModule} from "./eventModule";
import {ModuleNames} from "./interface";
import {ModulePropertyWrapper, ModuleWrapper} from "./moduleManager";
import {fetchQueryColumn} from "@src/http/queryColumn";
import {BizListPresenter} from "@src/bizList/bizListPresenter";
import {ListDataLoader} from "@src/http/listDataLoader";
import {BizListModel} from "@src/bizList/core/bizList";
import {fetchQuerySchema} from "@src/http/querySchema";
import localforage from "localforage";
import {QuerySolutionHelper} from "@src/bizList/core/querySolutionHelper";
import OperatorStore from "@src/operator";

export const QUERY_SOLUTION_PREFIX = "__QUERY_SOLUTION__";

// 整个页面的初始化模块
@autobind
@ModuleWrapper(ModuleNames.ListInitiModule)
export class ListInitiModule extends BaseModule {
  @ModulePropertyWrapper(ModuleNames.Presenter) private presenter: BizListPresenter;
  @ModulePropertyWrapper(ModuleNames.EventModule) private eventModule: EventModule;

  public apply() {
    this.eventModule.on(LifecycleEvent.onModuleReady, this.loadData);
  }

  // 加载tempalte、metadata、formData数据
  private async loadData() {
    try {
      const {presenter} = this;
      // @TODO: 考虑抽离出去
      const [queryColumn, querySchema] = await Promise.all([
        fetchQueryColumn(presenter.entityName),
        fetchQuerySchema(presenter.entityName),
      ]);
      // 获取form表单数据

      presenter.queryColumn = queryColumn as any;
      presenter.querySchema = querySchema as any;

      presenter.model = new BizListModel({
        entityName: presenter.entityName,
        queryColumn: presenter.queryColumn,
        querySchema: presenter.querySchema,
      });

      await this.loadQuerySolutionData();

      const dataLoader: ListDataLoader = new ListDataLoader({
        entityName: presenter.entityName,
        presenter,
        // @TODO: 暂时写死
      });

      presenter.dataLoader = dataLoader;

      dataLoader.queryBuilder.setCriteriaStr(this.presenter.querySolution.getQueryStr());
      await dataLoader.query();

      presenter.listData = dataLoader.data;

      this.eventModule.dispatch(LifecycleEvent.onLoadedListInitData);
    } catch {
      console.log("获取列表方案失败")
    }
  }

  // 获取查询方案的数据
  private async loadQuerySolutionData() {
    const {presenter} = this;

    const result = (await localforage.getItem(
      `${OperatorStore.getID()}_${this.presenter.querySchema.id}_${QUERY_SOLUTION_PREFIX}options`,
    )) as any;
    const {values = {}, valueTypes} = result || {};

    presenter.querySolution = QuerySolutionHelper.createQuerySchema({
      querySchema: presenter.querySchema,
      options: {
        values,
        valueTypes,
      },
    });

    // this.eventModule.dispatch(LifecycleEvent.onLoadedListInitData);
  }
}

import { ENUM_Entity_File } from "@packages/gen/constants/enums/entityName";
import { FileFormPresenter, FileListPresenter } from "@src/pages/file";
import { bizFormRoute } from "../bizFormRouter";
import { bizListRouter } from "../bizListRouter";

export default [
  ...bizFormRoute({
    entityName: ENUM_Entity_File,
    createPresenter: (options: any) => {
      // const { ProductFormPresenter } = await import(
      //   "@src/pages/product/productFormPresenter"
      // );
      // return new ProductFormPresenter(options);

      return new FileFormPresenter(options);
    },
  }),
  ...bizListRouter({
    entityName: ENUM_Entity_File,
    createPresenter: (options: any) => {
      return new FileListPresenter(options);
    },
  })
];

import EN_Article from '@packages/gen/types/entities/Article';
import {IBizAction} from '@src/bizAction/interface';
import {BizListPresenter} from '@src/bizList/bizListPresenter';
import {BizListConfig} from '@src/bizList/interface';

export class ArticleListPresenter<T = EN_Article> extends BizListPresenter<T> {
  public listBizConfig (config: BizListConfig<T>): BizListConfig<T> {
    config.column = {
      name: {
        cellRender: (val) => {
          return <span style={{color: 'red'}}>{val}</span>;
        }
      }
    };

    config.listRowActionManager.use((actions: Array<IBizAction>, data: any): Array<IBizAction> => {
      // if (data.id === 'db56a548-b6f8-4bd4-8886-051dcde9858b') {
      //   return [];
      // }
      return actions;
    });
    return config;
  }
}

import localforage from 'localforage';
import {BIZ_AUTH} from '@src/common/constants/token';
import {Auth} from '@src/interfaces/auth';
import { CDN_URL } from '@src/config';

let authInfo: Auth;

async function getAuthInfo () {
  authInfo = await localforage.getItem(BIZ_AUTH);
}

getAuthInfo();

export const UPYUN_UPLOAD_URL = 'https://v0.api.upyun.com';
export const UPYUN_BUCKET = 'vwood-upyun';

interface UpyunConfigOptions {
  name: string;
  action: string;
  headers?: {
    authorization: string;
  };
  upyun: {
    token: string;
    policy: string;
  };
  url: string;
}
export const getUpyunConfig = (): UpyunConfigOptions => {
  return {
    name: 'file',
    action: `${ UPYUN_UPLOAD_URL }/${ UPYUN_BUCKET }`,
    upyun: authInfo?.upyun,
    url: CDN_URL
    // headers: {
    //   authorization: authInfo?.upyun?.token,
    // },
  };
};

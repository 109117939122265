/**
 * Section主要用于分区，用户可以选择将不同的字段放在不同的区块中
 */
import {BizFormPresenter} from '@src/bizForm/bizFormPresenter';
import {matchComponent} from '@src/bizForm/components';
import {EntityFieldViewer} from '@src/bizForm/components/EntityFieldViewer';
import {FormMode} from '@src/bizForm/formMode';
import BizSection from '@src/components/BizSection';
import {ITemplateColumn} from '@src/interfaces/template';
import {TemplateSection} from '@src/pages/devtool/bizForm/form/template';
import {Input, Row} from 'antd';
import {computed} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {FieldWrapper} from '../../FieldWrapper';
import {FormFieldElement, FormFieldElementProps} from '../FormFieldElement';
import style from './style.module.scss';

interface ISection {
  template: TemplateSection;
  presenter: BizFormPresenter;
  mode: FormMode;
}

@observer
export default class Section extends React.Component<ISection> {
  getFieldProps = (col: ITemplateColumn) => {
    const {
      presenter,
      presenter: {model, form},
      mode,
    } = this.props;
    const field = model.getField(col.path)
    const options: FormFieldElementProps = {
      key: col.path,
      path: col.path,
      model: field,
      field: form.select(col.path),
      mode: mode,
      presenter,
    };
    if (field) {
      if (field.isEnum) {
        options.enumType = field.enumType
      }
      if (field.isRefer) {
        options.referType = field.referType
      }
    }

    return options;
  };

  fieldRender = () => {
    const {template, mode, presenter} = this.props;
    if (mode === FormMode.View) {
      return template.columns.map((col) => (
        <FormFieldElement {...this.getFieldProps(col)} key={col.path}>
          {(props: any) => (
            <EntityFieldViewer
              {...props}
              entityName={presenter.entityName}
              bizType={props.model.bizType}
              isRefer={props.model.isRefer}
            />
          )}
        </FormFieldElement>
      ));
    }
    return template.columns.map((col) => (
      <FormFieldElement {...this.getFieldProps(col)}>{(props: any) => matchComponent(props)}</FormFieldElement>
    ));
  };

  render() {
    const {template} = this.props;
    return (
      <BizSection
        className={style['biz-form_section']}
        leftElement={<span className={style['section_title']}>{template.title}</span>}
        content={<Row>{this.fieldRender()}</Row>}
      />
    );
  }
}

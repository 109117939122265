import {http} from './http';

export function fetchEntity (entityName) {
  return http({
    url: `/entity/${ entityName }`,
    method: 'get'
  });
}

export function fetchEntities () {
  return http({
    url: '/entity/list',
    method: 'get'
  });
}

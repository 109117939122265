import {http} from './http';

export function fetchQuerySchema (entityName: string): any {
  return http({
    url: `/querySchema/${ entityName }`,
    method: 'get'
  });
}

export function createQuerySchema (data: any) {
  return http({
    url: '/querySchema',
    method: 'post',
    data
  });
}

export function updateQuerySchema (data: any) {
  return http({
    url: '/querySchema',
    method: 'put',
    data
  });
}

export function removeQuerySchema (data: any) {
  return http({
    url: '/querySchema',
    method: 'delete',
    data
  });
}

export function fetchQuerySchemaList (params = {}) {
  return http({
    url: '/querySchema/list',
    method: 'get',
    params
  });
}

import {TemplateFormPresenter, TemplateListPresenter} from '@src/pages/template';
import {bizFormRoute} from '../bizFormRouter';
import {bizListRouter} from '../bizListRouter';

export default [
  ...bizFormRoute({
    entityName: 'Template',
    createPresenter: (options: any) => {
      // const { ProductFormPresenter } = await import(
      //   "@src/pages/product/productFormPresenter"
      // );
      // return new ProductFormPresenter(options);

      return new TemplateFormPresenter(options);
    }
  }),
  ...bizListRouter({
    entityName: 'Template',
    createPresenter: (options: any) => {
      return new TemplateListPresenter(options);
    }
  })
];

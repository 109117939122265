import {ENUM_Entity_Workflow} from "@packages/gen/constants/enums/entityName";
import {FormMode} from "@src/bizForm/formMode";
import {WorkflowListPresenter} from "@src/pages/workflow/workflowListPresenter";
import React from "react";
import {Route} from "react-router-dom";
import {bizFormRoute} from "../bizFormRouter";
import {bizListRouter} from "../bizListRouter";

export default [
  // ...bizFormRoute({
  //   entityName: ENUM_Entity_Workflow,
  //   createPresenter: (options: any) => {
  //     // const { ProductFormPresenter } = await import(
  //     //   "@src/pages/product/productFormPresenter"
  //     // );
  //     // return new ProductFormPresenter(options);

  //     return new WorkflowFormPresenter(options);
  //   },
  // }),
  ...bizListRouter({
    entityName: ENUM_Entity_Workflow,
    createPresenter: (options: any) => {
      return new WorkflowListPresenter(options);
    },
  }),
];

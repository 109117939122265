import {autobind} from 'core-decorators';
import {LoadingStatus} from '@src/components/LoadingContainer';
import {observable} from 'mobx';
import {ModuleManager} from '@src/bizForm/modules/moduleManager';
import {ModuleNames} from '@src/bizForm/modules/interface';
import {LifecycleEvent} from '@src/bizForm/lifecycleEvent';
import {EventModule} from '@src/bizForm/modules';
import {ListInitiModule} from '@src/bizForm/modules/listInitiModule';
import {BizListModel} from './core/bizList';
import {IQuerySchema} from '@src/interfaces/queryScheme';
import {IQueryColumn} from '@src/interfaces/queryColumn';
import {QuerySolution} from './core/querySolution';
import {ListDataLoader} from '@src/http/listDataLoader';
import {ListModule} from '@src/bizForm/modules/listModule';
import {BizListConfig, ListData} from './interface';
import {BizListActionManager} from '@src/bizAction/bizListActionManager';
import {BizListRowActionManager} from '@src/bizAction/bizListRowActionManager';

export interface IBizFormPresenterOptions {
  entityName: string;
}

@autobind
export class BizListPresenter<T = any> {
  [x: string]: any;
  // 元数据
  public queryColumn: IQueryColumn;

  // 模板数据
  public querySchema: IQuerySchema;

  public querySolution: QuerySolution;

  public entityName: string;

  public model: BizListModel;

  public dataLoader: ListDataLoader;
  //
  public listData: ListData = {
    data: [],
    count: 0,
    pageNo: 1,
    pageSize: 20, // 默认分页10条
    hasNextPage: false
  };

  public config: BizListConfig<T>;

  @observable
  public loadingStatus: LoadingStatus = LoadingStatus.None;

  // 模块管理属性
  public modules: ModuleManager;
  presenter: any;

  public getModule (moduleName: string) {
    return this.modules.get(moduleName);
  }

  // 获取所有master的字段属性的数组, logicPath 字段的所属路径前缀
  public getFields (logicPath: string) {
    // return this.model.fields.filter(field => field.path.startsWith(logicPath));
  }

  constructor (public readonly options: IBizFormPresenterOptions) {
    this.entityName = options.entityName;
    // this.initData();
    // this.config = this._listBizConfig();
    this.config = this._listBizConfig();
  }

  public initData = () => {
    this.initModules();
    this.getModule(ModuleNames.EventModule).on(LifecycleEvent.onLoadedListInitData, this.initList);
    this.getModule(ModuleNames.EventModule).on(LifecycleEvent.afterSave, this.afterSave);
    this.getModule(ModuleNames.EventModule).on(LifecycleEvent.onLoadedListData, this.loadListData);
    this.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.onModuleReady);
  };

  private initModules = () => {
    this.modules = new ModuleManager({
      seeds: {
        [ ModuleNames.Presenter ]: this
      },
      modules: [ EventModule, ListInitiModule, ListModule ],
      presenter: this
    });
  };

  // // 初始化BizListMode数据
  public initList () {
    this.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.onListInitialized);
    this.loadingStatus = LoadingStatus.Complete;
    // this.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.onLoadedListData);
  }

  private async loadListData () {
    try {
      this.loadingStatus = LoadingStatus.Complete;
    } catch (err) {
      this.loadingStatus = LoadingStatus.Failed;
    }
  }

  private afterSave (id: string) {
    // RouteUtils.toForm({
    //   entityName: this.entityName,
    //   mode: FormMode.View,
    //   id,
    // })
  }

  // start 生命周期
  public onBeforeSave (data: any) {
    return data;
  }

  // end 生命周期

  public save () {
    this.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.onSave);
  }

  // 列表配置项
  /**
   * {
   *  path: {
   *     cellRender: (val, record) => ReactNode
   *
   *  }
   * }
   */
  public listBizConfig (config: BizListConfig<T>): BizListConfig<T> {
    // const listConfig = new ListConfig();
    return config;
  }

  private _listBizConfig (): BizListConfig<T> {
    const defaultConfig: BizListConfig<T> = {
      listActionManager: new BizListActionManager(),
      listRowActionManager: new BizListRowActionManager()
    };
    return this.listBizConfig(defaultConfig);
  }
}

/*
 * File: product copy.ts
 * Project: biz-form
 * File Created: Tuesday, 6th July 2021 11:05:53 pm
 * Author: zxtang (1061225829@qq.com)
 * -----
 * Last Modified: Tuesday, 6th July 2021 11:05:53 pm
 * Modified By: zxtang (1061225829@qq.com>)
 * -----
 * Copyright 2017 - 2021 Your Company, Your Company
 */
import { OrderFormPresenter } from "@src/pages/order/orderFormPresenter";
import { bizFormRoute } from "../bizFormRouter";

export default [
  ...bizFormRoute({
    entityName: "Order",
    createPresenter: (options: any) => {
      // const { ProductFormPresenter } = await import(
      //   "@src/pages/product/productFormPresenter"
      // );
      // return new ProductFormPresenter(options);

      return new OrderFormPresenter(options);
    },
  }),
];

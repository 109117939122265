import {IQuerySchemaItem} from '@src/interfaces/queryScheme';
import {metadata} from '@src/metadata/metadata';
import {Observer} from 'mobx-react';
import * as React from 'react';
import BoolSelect from '../BoolSelect';
import {Count} from '../Count';
import {CountBetween} from '../CountBetween';
import {DatePicker} from '../DatePicker';
import {DatePickerBetween} from '../DatePickerBetween';
import {DatetimePicker} from '../DatetimePicker';
import {DatetimePickerBetween} from '../DatetimePickerBetween';
import EnumSelect from '../EnumSelect';
import {Input} from '../Input';
import {Numeric} from '../Numeric';
import {NumericBetween} from '../NumericBetween';
import Refer from '../Refer';
import {ValueType} from './valueTypeMaps';

function getTextInut (valueType: ValueType) {
  return Input;
}

function getBoolSelect (valueType: ValueType) {
  return BoolSelect;
}

function getCountInut (valueType: ValueType) {
  switch (valueType) {
    case ValueType.between:
      return CountBetween;
    default:
      return Count;
  }
}

function getNumericInut (valueType: ValueType) {
  switch (valueType) {
    case ValueType.between:
      return NumericBetween;
    default:
      return Numeric;
  }
}

function getDatePicker (valueType: ValueType) {
  switch (valueType) {
    case ValueType.between:
      return DatePickerBetween;
    default:
      return DatePicker;
  }
}

function getDatetimePicker (valueType: ValueType) {
  switch (valueType) {
    case ValueType.between:
      return DatetimePickerBetween;
    default:
      return DatetimePicker;
  }
}

function getReferSelect (valueType: ValueType) {
  return ReferSelectWrapper;
}

function ReferSelectWrapper (props: {
  fieldName: string;
  entityName: string;
  querySchemaItems: Array<IQuerySchemaItem>;
  onChange: (...data: any) => void;
  value: any;
  className: string;
  isMutil?: boolean;
}) {
  const {fieldName, entityName, querySchemaItems, className, isMutil} = props;
  const entityField = metadata.getEntity(entityName).getFieldLastNode(fieldName);
  if (!entityField) {
    return null;
  }

  const logicPath = entityField.referType;

  const getName = React.useCallback((path: string) => {
    const prefixEntityLen = logicPath.split('.').length;
    return path.split('.').slice(prefixEntityLen).join('');
  }, []);

  const queryFields = querySchemaItems.map((field) => getName(field.path)).filter((fieldName) => !!fieldName);
  const onChange = (value: any) => {
    if (!isMutil) {
      value = [ value ];
    }
    props.onChange && props.onChange(value);
  };

  return (
    <Observer>
      {() => (
        <Refer
          className={className}
          entityName={entityField.referType}
          queryFields={queryFields}
          // @TODO: 目前只考虑单值，多值后面兼容
          isMutil={isMutil}
          value={isMutil ? props.value : props.value ? props.value[ 0 ] : undefined}
          onChange={onChange}
        />
      )}
    </Observer>
  );
}

function getEnumSelect (valueType: ValueType) {
  return EnumSelect;
}

/**
 * 根据不同的数据类型展示不同的查询条件选项
 */

export const getQuerySchemaInput = (bizType: string, valueType: ValueType) => {
  switch (bizType) {
    case 'refer':
      return getReferSelect(valueType);
    case 'text':
    case 'textarea':
    case 'url':
      return getTextInut(valueType);
    case 'bool':
      return getBoolSelect(valueType);
    case 'count':
      return getCountInut(valueType);
    case 'numeric':
      return getNumericInut(valueType);
    case 'date':
      return getDatePicker(valueType);
    case 'datetime':
      return getDatetimePicker(valueType);
    case 'enum':
      return getEnumSelect(valueType);
    default:
      return getTextInut(valueType);
  }
};

/*
 * File: metadata copy.js
 * Project: biz-form
 * File Created: Tuesday, 8th June 2021 9:35:35 pm
 * Author: zxtang (1061225829@qq.com)
 * -----
 * Last Modified: Tuesday, 8th June 2021 9:35:35 pm
 * Modified By: zxtang (1061225829@qq.com>)
 * -----
 * Copyright 2017 - 2021 Your Company, Your Company
 */
import {fetchEntities} from './entity';
import {fetchMetadatas} from './metadata';

/**
 * 根据版本号检查本地的数据与服务器上的元数据是否一致，如果本地的版本比服务低则返回数据，否则不返回;
 * 根据返回的数据更新本地的数据
 * 本地的metadata、entity数据存储在localStorage中
 *
 * 采用这种方式而不是json文件的方式，是因为后端改了模型之后前端不用发版，只需要在启动应用时检查一下即可
 *
 * @param {*} param0
 * @returns
 */
export async function fetchBaseMetadata () {
  const [ descriptionsData ] = await Promise.all([
    fetchEntities()
    // fetchMetadatas(),
  ]);

  return {
    descriptions: descriptionsData.data
    // metadatas,
  };
}

import * as React from 'react';
import { Input as AntdInput, InputProps } from 'antd';
import { IBizFormComponentOProps } from '../interface';
import { FormMode } from '@src/bizForm/formMode';
import { Numeric } from '../Numeric';

interface ICount extends IBizFormComponentOProps {

}

/**
 *整数
 * @param props 
 * @returns 
 */
export function Count(props: ICount) {
    return <Numeric {...props} precision={0}/>
}

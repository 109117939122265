import { BizListPresenter } from "@src/bizList/bizListPresenter"
import React from "react"
import _get from 'lodash/get';
import { ModuleNames } from "@src/bizForm/modules/interface"
import { LifecycleEvent } from "@src/bizForm/lifecycleEvent"
/**
 * 列表行使用
 */
export const RefreshAction = {
    key: "refresh",
    label: "刷新",
    options: {
        onClick: (presenter: BizListPresenter, data?: any) => {
            presenter.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.reloadBizList);
        }
    }
}

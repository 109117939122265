import {metadata} from "@src/metadata/metadata";
import React, {memo} from "react";
import {EnumSelectProps} from "./types";
import {IEntityFieldViewer} from "../EntityFieldViewer";
import "./style.scss";

export default memo(function EnumSelectViewer(props: EnumSelectProps & IEntityFieldViewer): JSX.Element {
  const {value, enumType} = props;
  const name = metadata.getEnum(enumType)?.getTitle(value);
  return <span>{name}</span>;
});

import {toJS} from "mobx";
import {MSTFormField} from "../MSTFormField";

export class ValidatorUtils {
  /**
   *
   * @param field
   * @param title : 元素label
   * @returns
   */
  static required(title: string) {
    return (field: MSTFormField) => {
      const value = toJS(field.value);
      if (value === undefined || value === null || value === "" || (Array.isArray(value) && !value.length)) {
        return `${title} 必填`;
      }
      return "";
    };
  }

  // 校验最小值，字段类型必须是数字才回生效
  static min(title: string, num: number) {
    return (field: MSTFormField) => {
      if (Number.isNaN(Number(field.value)) && Number(field.value) < num) {
        return `${title}不能小于${num}`;
      }
      return "";
    };
  }

  // 校验最小值，字段类型必须是数字才回生效
  static max(title: string, num: number) {
    return (field: MSTFormField) => {
      if (Number.isNaN(Number(field.value)) && Number(field.value) > num) {
        return `${title}不能大于${num}`;
      }
      return "";
    };
  }
  // 最小长度
  static minLen(title: string, len: number) {
    return (field: MSTFormField) => {
      if (Number.isNaN(Number(field.value)) && Number(field.value) < len) {
        return `${title}长度不能小于${len}`;
      }
      return "";
    };
  }
  // 最大长度
  static maxLen(title: string, len: number) {
    return (field: MSTFormField) => {
      if (Number.isNaN(Number(field.value)) && Number(field.value) > len) {
        return `${title}长度不能大于${len}`;
      }
      return "";
    };
  }
}

import {http} from './http';

export function fetchTemplate (entityName) {
  return http({
    url: `/template/${ entityName }`,
    method: 'get'
  });
}

export function createTemplate (data) {
  return http({
    url: '/template',
    method: 'post',
    data
  });
}

export function updateTemplate (data) {
  return http({
    url: '/template',
    method: 'put',
    data
  });
}

export function removeTemplate (data) {
  return http({
    url: '/template',
    method: 'delete',
    data
  });
}

export function fetchTemplateList (params = {}) {
  return http({
    url: '/template/list',
    method: 'get',
    params
  });
}

import {BackendDescription, BackendDescriptionField} from './interface';

export class EnumValue {
  public readonly title: string;
  public readonly name: string;
  public readonly value: string | number | boolean;
  label: any;

  constructor (data: BackendDescriptionField) {
    this.title = data.title;
    this.value = data.value;
    this.name = data.name;
  }
}

export class Enum {
  public readonly title: string;
  public readonly name: string;
  public readonly description: string;
  public readonly values: EnumValue[];

  constructor (data: BackendDescription) {
    this.title = data.title;
    this.name = data.name;
    this.description = data.description;
    this.values = data.fields.map((field: BackendDescriptionField) => new EnumValue(field));
  }

  public getValue (enumValueName: string) {
    return this.values.find((value) => value.name === enumValueName);
  }

  public getTitle(enumValue: string | number | boolean): string | undefined {
    return this.values.find((value) => value.value === enumValue)?.title;
  }

  public fields (): EnumValue[] {
    return this.values;
  }
}

import {DataLoader} from '@src/http/dataLoader/dataLoader';
import {ReferDataLoader} from '@src/http/referDataLoader/index';
import {Observer} from 'mobx-react';
import * as React from 'react';
import {MutilSelectTable, SingleSelectTable} from '../SelectTable';
import {LoadingStatus} from '@src/components/LoadingContainer';
import {metadata} from '@src/metadata/metadata';

interface IRefer {
  entityName: string; // 业务对象名称
  value: any; // 传入的值
  onChange: (value: any) => void; // 选择数据时触发
  queryFields: Array<string>;
  className?: string;
  isMutil?: boolean; //多选
  id?: string;
  disabled?: boolean;
}

/**
 * 根據entityName自動查詢对应的数据
 * 系统里可以作为refer的数据都应该有id、title字段。ReferSelect组件读取对应的属性即可
 * refer只显示entity本身的字段，子表的字段不会显示
 * ReferSelect应作为受控组件使用
 * @param props
 * @returns
 */
function Refer (props: IRefer) {
  const {queryFields, entityName, isMutil} = props;

  const dataloader: DataLoader = React.useMemo(() => {
    return new ReferDataLoader({
      entityName,
      queryFields: queryFields.filter((fieldName) => !!fieldName)
    });
  }, [ entityName ]);

  // 从presenter的template中筛选referEntityName开发的属性
  const getFields = () => {
    const entity = metadata.getEntity(entityName);
    const fields = queryFields
      .map((fieldName) => {
        const entityField = entity.getFieldLastNode(fieldName);
        if (entityField) {
          return {
            name: fieldName,
            label: entityField.title
          };
        }
        return null;
      })
      .filter((item) => !!item);

    // 自动补充id字段
    if (!fields.some((item) => item.name === 'id')) {
      fields.unshift({
        name: 'id',
        label: 'id'
      });
      fields.push({
        name: 'name',
        label: entity.getField('name').title
      });
    }

    return fields;
  };

  const onChange = (value: any) => {
    props?.onChange(value);
  };

  const onSearch = (value: any) => {
    dataloader.query();
  };

  const SelectTable = isMutil ? MutilSelectTable : SingleSelectTable;

  return (
    <Observer>
      {() => (
        <SelectTable
          // loading={dataloader.loadingStatus}
          id={props.id}
          disabled={props.disabled}
          value={props.value}
          onChange={onChange}
          onSearch={onSearch}
          fields={getFields()}
          dataSource={dataloader.data}
          className={props.className}
        />
      )}
    </Observer>
  );
}

export default Refer;

import {EntityFieldViewer} from "@src/bizForm/components/EntityFieldViewer";
// import {RowActionBar} from '@src/components/RowActionBar';
import {Table} from "antd";
import {toJS} from "mobx";
import {Observer} from "mobx-react-lite";
import * as React from "react";
import {BizListPresenter} from "./bizListPresenter";
import _get from "lodash/get";

import {BizColumn} from "./core/bizList";
import {ModuleNames} from "@src/bizForm/modules/interface";
import "./style/index.scss";
import {ColumnsType, ColumnType} from "antd/lib/table";
import {RenderedCell} from "rc-table/lib/interface";
import {LifecycleEvent} from "@src/bizForm/lifecycleEvent";
import {ListRowActionBar} from "@src/bizAction/ActionBar/ListRowActionBar";
import {Resizable} from "react-resizable";
import type {ResizeCallbackData} from "react-resizable";
import OperatorStore from "@src/operator";

const BIZ_LIST_COLUMN_OPTIONS = "biz-list-column-options";

const getLocalstorageData = () => {
  try {
    const data = localStorage.getItem(BIZ_LIST_COLUMN_OPTIONS + "-" + OperatorStore.getID());
    return data ? JSON.parse(data) : {};
  } catch {
    return {};
  }
};

interface IBizListBody {
  presenter: BizListPresenter;
}

interface SBizListBody {
  columns: ColumnType<any>[];
}

const ResizableTitle = (
  props: React.HTMLAttributes<any> & {
    onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void;
    width: number;
  },
) => {
  const {onResize, width, ...restProps} = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{enableUserSelectHack: false}}
    >
      <th {...restProps} />
    </Resizable>
  );
};

/**
 * 用于渲染table
 */
export class BizListBody extends React.Component<IBizListBody, SBizListBody> {
  columnOptions: {
    [key: string]: {
      width?: number; // 宽度
    };
  } = getLocalstorageData();

  state = {
    columns: [] as ColumnType<any>[],
  };

  constructor(props: IBizListBody) {
    super(props);
    this.state.columns = this.getColumns();
  }

  componentDidMount() {
    // this.props.presenter.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.onFormCreated);
  }

  getCellRender = (col: BizColumn) => {
    const cellRender = _get(this.props.presenter.config, `${col.path}.cellRender`) as unknown as (
      value: any,
      record: any,
      index: number,
    ) => React.ReactNode | RenderedCell<any>;
    return cellRender
      ? cellRender
      : (value: any) => <EntityFieldViewer entityName={this.props.presenter.entityName} value={value} {...col} />;
  };

  setLocalstorage = (dataIndex: string, width: number) => {
    if (!this.columnOptions[dataIndex]) {
      this.columnOptions[dataIndex] = {};
    }
    this.columnOptions[dataIndex].width = width;

    localStorage.setItem(
      BIZ_LIST_COLUMN_OPTIONS + "-" + OperatorStore.getID(),
      JSON.stringify(this.columnOptions || {}),
    );
  };

  handleResize =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, {size}: ResizeCallbackData) => {
      const newColumns = [...this.state.columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
      };
      this.setState({
        columns: newColumns,
      });
      this.setLocalstorage(newColumns[index]?.dataIndex as string, newColumns[index].width as number);
    };

  getColumns(): ColumnsType<any> {
    return [
      ...this.props.presenter.model.columns.map((col, index: number) => ({
        ...col,
        dataIndex: col.path.split("."), // antd table  支持string | string[]
        ellipsis: {
          showTitle: true,
        },
        textWrap: "word-break",
        width: this.columnOptions?.[col.path.split(".") as unknown as string]?.["width"] || 200,
        render: this.getCellRender(col),
        onHeaderCell: (column: ColumnType<any>) => ({
          width: column.width,
          onResize: this.handleResize(index),
        }),
      })),
      {
        title: "操作",
        fixed: "right",
        width: 200,
        render: (_: any, item: any, index: number) => {
          return <ListRowActionBar presenter={this.props.presenter} data={item} />;
        },
      },
    ];
  }

  render() {
    return this.renderContent();
  }

  renderContent = () => {
    const {presenter} = this.props;
    if (!presenter.querySchema) {
      return null;
    }
    return (
      <Observer>
        {() => (
          <Table
            className={"bizList-table"}
            size="small"
            rowKey="id"
            rowSelection={this.getRowSelection()}
            components={{
              header: {
                cell: ResizableTitle,
              },
            }}
            columns={this.state.columns}
            dataSource={toJS(presenter.listData.data)}
            scroll={{
              x: 1300,
              // 分页、查询后滚动到列表顶部
              scrollToFirstRowOnChange: true,
            }}
            bordered
            pagination={{
              current: presenter.listData.pageNo,
              total: presenter.listData.count,
              pageSize: presenter.listData.pageSize,
              showQuickJumper: true,
              showSizeChanger: true,
              onChange: this.handlePageChange,
            }}
            // sticky
          />
        )}
      </Observer>
    );
  };

  private onSelectChange = (selectedRowKeys: string[], selectedRows: []) => {
    this.props.presenter.getModule(ModuleNames.ListModule).setSelectedRowKeys(selectedRowKeys);
    this.forceUpdate();
  };

  private getRowSelection = (): any => {
    const keys = this.props.presenter.getModule(ModuleNames.ListModule).getSelectedRowKeys();
    return {
      selectedRowKeys: keys,
      onChange: this.onSelectChange,
      fixed: true,
    };
  };

  private query = () => {
    this.props.presenter.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.queryListData);
  };

  private handlePageChange = (page: number, pageSize?: number) => {
    this.props.presenter.listData.pageSize = pageSize;
    this.props.presenter.listData.pageNo = page;

    this.query();
  };
}

import {BackendDescription, IReferConfig} from "./interface";
import {StringUtils} from "@src/utils/stringUtils";
import {Entity, entityFactory} from "./entity";
import {Enum} from "./enum";

/**
 * MetadataBase 主要处理元数据的查询和加载
 */
class MetadataBase {
  entities: {[entityName: string]: Entity} = {};
  refers: {[entityName: string]: {[referName: string]: IReferConfig}} = {}; // 每个entity下关联的外键
  enums: {[entityName: string]: Enum} = {};

  constructor() {
    // this.init();
  }

  getList(): Array<Entity> {
    return Object.values(this.entities);
  }

  getEntity(entityName: string): Entity {
    return this.entities[entityName];
  }

  getEnum(enumName: string): Enum {
    return this.enums[enumName];
  }

  getEnumOptions(enumName: string): Array<{
    label: string;
    value: string | number | boolean;
  }> {
    return this.enums[enumName]?.fields()?.map((enumField) => ({
      label: enumField.label,
      value: enumField.value,
    }));
  }

  getReferConfig(enttityName: string, referName: string) {
    let refer;
    try {
      refer = this.refers[enttityName][referName];
    } catch {
      console.warn(`entity "${enttityName}" does not has the "${referName}" referConfig`);
    }
    return refer;
  }

  public init({descriptions = [], metadatas = []}: any) {
    descriptions.forEach((item: BackendDescription) => {
      // const metadata = metadatas.find((metadata: IMetadata) => metadata.entityName === item.name);
      // eslint-disable-next-line default-case
      switch (item.type) {
        case "record":
          this.initEntity(item);
          break;
        case "enum":
          this.initEnum(item);
          break;
      }
    });
  }

  initEntity(entity: BackendDescription) {
    this.entities[entity.name] = entityFactory(entity);
    this.initReferInfo(entity.name, entity);
  }

  initReferInfo(entityName: string, entity: BackendDescription) {
    if (!this.refers[entityName]) {
      this.refers[entityName] = {};
    }
    /**
     * 设置每个Entity关联的refer信息
     */
    entity.fields.forEach((field) => {
      if (field.referInfo) {
        // 使用外键在entity tepmlate上的属性值作为key，方便取值
        this.refers[entityName][StringUtils.lowerCapital(field.referInfo.referEntityName)] = {
          entityName: field.referInfo.referEntityName,
          name: field.name,
        };
      }
    });
  }

  initEnum(entity: BackendDescription) {
    this.enums[entity.name] = new Enum(entity);
  }
}

export const metadataBase = new MetadataBase();

import {BetweenWrapper} from '../BetweenWrapper';
import {DatetimePicker} from '../DatetimePicker';
import {IBizFormComponentOProps} from '../interface';

export interface IDatetimePickerBetween extends IBizFormComponentOProps {
  value: Array<number>;
}
/**
 *
 * @param props 日期组件
 * @returns
 */
export const DatetimePickerBetween = (props: IDatetimePickerBetween) => {
  const value = Array.isArray(props.value) ? props.value : [];

  const onChange = (index: number, val: number) => {
    value[ index ] = val;
    props?.onChange(value);
  };

  return <BetweenWrapper {...props} value={value} onChange={onChange} component={DatetimePicker} />;
};

import { ArticleFormPresenter, ArticleListPresenter } from "@src/pages/article";
import { bizFormRoute } from "../bizFormRouter";
import { bizListRouter } from "../bizListRouter";

export default [
  ...bizFormRoute({
    entityName: "Article",
    createPresenter: (options: any) => {
      // const { ProductFormPresenter } = await import(
      //   "@src/pages/product/productFormPresenter"
      // );
      // return new ProductFormPresenter(options);

      return new ArticleFormPresenter(options);
    },
  }),
  ...bizListRouter({
    entityName: "Article",
    createPresenter: (options: any) => {
      return new ArticleListPresenter(options);
    },
  })
];

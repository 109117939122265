import * as React from "react";
import {Route} from "react-router-dom";
const Login = React.lazy(() => import("@src/pages/auth/login"));
const Registry = React.lazy(() => import("@src/pages/auth/registry"));
const GithubCallback = React.lazy(() => import("@src/pages/auth/login/github"));
// const Somo = React.lazy(() => import("@src/pages/somo"));

const NoSiderRoutes = [
  <Route exact key="/login" path="/login" component={Login}></Route>,
  <Route exact key="/registry" path="/registry" component={Registry}></Route>,
  <Route exact key="/github/callback" path="/github/callback" component={GithubCallback}></Route>,
  // <Route exact key="/somo" path="/somo" component={Somo}></Route>,
];
export default NoSiderRoutes;

import React from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import {IBizActionManager} from '@src/bizAction/interface';

export interface BizListConfig<T> {
  // [path: string]: {
  //   cellRender: (val: any, record: any) => React.ReactNode;
  // };
  // actionBar: Array<IBizAction>; // 列表操作按钮
  // rowActionBar: Array<IBizRowAction>; // 行操作按钮

  column?: {
    [path: string]: {
      cellRender?: (val: any, record: any) => React.ReactNode;
    };
  };
  listActionManager: IBizActionManager<T>;
  listRowActionManager: IBizActionManager<T>;
}

export interface ListData {
  data: Array<any>;
  count: number;
  pageSize: number;
  pageNo: number;
  hasNextPage?: boolean;
}

export const defaultListData: ListData = {
  data: [],
  pageSize: 20,
  pageNo: 1,
  count: 0,
  hasNextPage: false
};

export const getDefaultListData = () => _cloneDeep(defaultListData);

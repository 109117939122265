import { ENUM_Entity_Email } from '@packages/gen/constants/enums/entityName';
import {EmailFormPresenter, EmailListPresenter} from '@src/pages/email';
import {bizFormRoute} from '../bizFormRouter';
import {bizListRouter} from '../bizListRouter';

export default [
  ...bizFormRoute({
    entityName: ENUM_Entity_Email,
    createPresenter: (options: any) => {
      // const { ProductFormPresenter } = await import(
      //   "@src/pages/product/productFormPresenter"
      // );
      // return new ProductFormPresenter(options);

      return new EmailFormPresenter(options);
    }
  }),
  ...bizListRouter({
    entityName: ENUM_Entity_Email,
    createPresenter: (options: any) => {
      return new EmailListPresenter(options);
    }
  })
];

import * as React from 'react';
import {Switch} from 'react-router-dom';
import BaseRoutes from './baseRoutes';
import CustomeRoutes from './customeRoutes';

export default function AppRouter () {
  return (
    <Switch>
      {BaseRoutes}
      {CustomeRoutes}
    </Switch>
  );
}

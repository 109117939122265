import { BizListPresenter } from "@src/bizList/bizListPresenter"
import React from "react"
import _get from 'lodash/get';
import { ModuleNames } from "@src/bizForm/modules/interface"
import { QueryActionModal } from "./QueryActionModal"
import { LifecycleEvent } from "@src/bizForm/lifecycleEvent"
import { Modals } from "@src/components/Modals";
/**
 * 列表行使用
 */
export const QueryAction = {
    key: "query",
    label: "查询",
    options: {
        onClick: (presenter: BizListPresenter, data?: any) => {
            Modals.show(QueryActionModal, {
                presenter: presenter,
                onOk: () => {
                    presenter.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.queryListData);
                }
            })
        }
    }
}

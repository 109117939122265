import {Input, Table} from 'antd';
import {toJS} from 'mobx';
import {observer} from 'mobx-react';
import React, {createRef} from 'react';
import _get from 'lodash/get';
import './style.scss';
import {EllipsisOutlined} from '@ant-design/icons';
import classnames from 'classnames';
import _uniqBy from 'lodash/uniqBy';
import { Modals } from '@src/components/Modals';
import AdvanceReferModal from './AdvanceReferModal';

const DEFAULT_width = 200; // 默认宽度

interface EntityItem {
  id: string;
}

export interface ISelectTable<T> {
  value: any;
  onChange: (value: T) => void;
  //   onInput: (value: T) => void;
  onSearch: (value?: string) => void;
  dataSource: T[];
  fields: Array<{
    name: string;
    label: string;
  }>;
  displayFieldName?: string; // 在input框中显示的字段
  loading?: boolean;
  className?: string;
  id?: string;
  disabled?: boolean;
}

interface SSelectTable {
  showTable: boolean;
}

@observer
export class SingleSelectTable<T> extends React.Component<ISelectTable<T>, SSelectTable> {
  _ref = createRef<HTMLDivElement>();

  get displayFieldName () {
    return this.props.displayFieldName || 'name';
  }

  constructor (props: ISelectTable<T>) {
    super(props);
    this.state = {
      showTable: false
    };
  }

  componentDidMount () {
    document.addEventListener('click', this.hiddenTable, false);
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.hiddenTable, false);
  }

  private hiddenTable = (e: any) => {
    if (this._ref.current && !this._ref.current.contains(e.target)) {
      this.closeTable();
    }
  };

  private getColumns = () => {
    const {fields = []} = this.props;
    return _uniqBy(fields, 'name').map((field) => ({
      title: field.label,
      dataIndex: field.name.split('.'),
      ellipsis: true
    }));
  };

  // 关闭下拉table
  private closeTable = () => {
    this.setState({
      showTable: false
    });
  };

  private rowOnClick = (record: T) => {
    this.props.onChange && this.props.onChange(record);
    this.closeTable();
  };

  // 行样式
  private rowClassName = (record: any, index: number, indent: number) => {
    if (this.props.value && this.props.value.id === record.id) {
      // 行被选中
      return 'select-table-row_selected';
    }

    return 'select-table-row';
  };

  private handleOnFocus = () => {
    if (!this.state.showTable) {
      this.setState({
        showTable: true
      });
      this.props?.onSearch();
    }
  };

  private handleOnChange = (e: any) => {
    this.handleOnFocus();
    this.props?.onChange(undefined);
  };

  private handleAdvanceRefer = (e: React.MouseEvent<HTMLSpanElement>): void => {
    const {value, dataSource, loading, disabled} = this.props;
    // 阻止向上冒泡
    e.stopPropagation();
    this.setState({
      showTable: false,
    })

    Modals.show(AdvanceReferModal, {
      columns: this.getColumns(),
      value,
      dataSource
    });
  }

  render () {
    const {showTable} = this.state;
    const {value, dataSource, loading, disabled} = this.props;
    return (
      <div ref={this._ref} id={this.props.id} className={classnames('select-table', this.props.className)}>
        <Input
          value={_get(value, this.displayFieldName, undefined)}
          onFocus={this.handleOnFocus}
          onChange={this.handleOnChange}
          // suffix={<span onClick={this.handleAdvanceRefer}><EllipsisOutlined /></span>}
          addonAfter={<span onClick={this.handleAdvanceRefer}><EllipsisOutlined /></span>}
          disabled={disabled}
        />

        <div className={'select-table-body'}>
          {showTable ? (
            <div className={'select-table-body-list'}>
              {/* <VirtualTable */}
              <Table
                // loading={loading}
                size="small"
                rowKey="id"
                pagination={false}
                bordered={true}
                columns={this.getColumns()}
                dataSource={(toJS(this.props.dataSource) || []) as any}
                scroll={{y: 300}}
                rowClassName={this.rowClassName}
                onRow={(record) => {
                  return {
                    onClick: () => this.rowOnClick(record as T), // 点击行
                    onDoubleClick: (event) => {},
                    onContextMenu: (event) => {},
                    onMouseEnter: (event) => {}, // 鼠标移入行
                    onMouseLeave: (event) => {}
                  };
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

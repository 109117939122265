

  // 文章
  export const ENUM_Entity_Article = "Article";

  // 分类
  export const ENUM_Entity_Category = "Category";

  // 协作
  export const ENUM_Entity_Collaboration = "Collaboration";

  // 评论
  export const ENUM_Entity_Comment = "Comment";

  // 邮件
  export const ENUM_Entity_Email = "Email";

  // 文件
  export const ENUM_Entity_File = "File";

  // 对象类型
  export const ENUM_Entity_ObjectType = "ObjectType";

  // 用户
  export const ENUM_Entity_Operator = "Operator";

  // 订单
  export const ENUM_Entity_Order = "Order";

  // 产品
  export const ENUM_Entity_Product = "Product";

  // sku
  export const ENUM_Entity_Sku = "Sku";

  // 模版
  export const ENUM_Entity_Template = "Template";

  // 租户
  export const ENUM_Entity_Tenant = "Tenant";

  // 租户用户关联信息
  export const ENUM_Entity_TenantOperatorAssociate = "TenantOperatorAssociate";

  // 代码演示
  export const ENUM_Entity_TinyCode = "TinyCode";

  // 站点
  export const ENUM_Entity_Website = "Website";

  // 教程
  export const ENUM_Entity_Workflow = "Workflow";

  // 教程明细
  export const ENUM_Entity_WorkflowItem = "WorkflowItem";


import {action, computed, makeAutoObservable, observable, ObservableMap, reaction} from 'mobx';
import {Interface} from 'readline';
import {MSTForm} from '../MSTForm';
import {IValidator} from './interface';

export interface IValidatorsOptions {
  validateFirst?: boolean; // 遇到第一个失败的校验时是否停止校验
  validators: {[path: string]: Array<IValidator>};
}

export interface IValidators {
  form: MSTForm;
  options: IValidatorsOptions;
}

export class Validators {
  private form: MSTForm;
  private options: IValidatorsOptions;

  private validators: ObservableMap<string, Array<IValidator>> = observable.map(new Map(), {deep: false});

  // key/value的形式创建性质结果的map
  public errorMessages: ObservableMap<string, Array<string>> = observable.map(
    new Map(),
    // { deep: false }
  );

  public hasError: boolean;

  constructor(params: IValidators) {
    // makeAutoObservable(this);
    this.form = params.form;
    this.options = params.options;

    const {validators = {}} = this.options;
    (Reflect.ownKeys(validators) as Array<string>).forEach((path) => {
      this.validators.set(path, validators[path] || []);
    });
  }

  public addValidator(path: string, validator: IValidator) {
    const validators = this.validators.get(path) || [];
    validators.push(validator);
    this.validators.set(path, validators);

    return () => this.removeValidator(path, validator);
  }

  public removeValidator(path: string, validator: IValidator) {
    let validators = this.validators.get(path) || [];
    validators = validators.filter((item) => item !== validator);
    this.validators.set(path, validators);
  }

  // 执行校验器，返回是否校验失败, 成功返回true
  @action
  public validate(): boolean {
    this.errorMessages.clear();
    this.hasError = false;

    for (let path of this.validators.keys()) {
      if (typeof path === 'string') {
        const errors = this.validateByPath(path);
        if (errors.length) {
          this.errorMessages.set(path, errors);
        } else {
          this.errorMessages.delete(path);
        }
      }
    }

    this.hasError = !!this.errorMessages.size;
    return !this.errorMessages.size;
  }

  // 执行校验器，返回是否校验失败
  @action
  public validateField(path: string): boolean {
    const errors = this.validateByPath(path);
    if (errors.length) {
      this.errorMessages.set(path, errors);
    } else {
      this.errorMessages.delete(path);
    }

    return !this.errorMessages.size;
  }

  public validateByPath(path: string): Array<string> {
    const validators = this.validators.get(path);
    const field = this.form.select(path);
    const messages = [];

    for (let idx = 0; idx < validators.length; idx++) {
      const msg = validators[idx](field);
      if (typeof msg !== 'string') {
        console.error(`validator return info must be string!`);
        return [];
      }
      if (msg) {
        messages.push(msg);
        if (this.options.validateFirst) {
          // 遇到第一个失败的校验
          break;
        }
      }
    }

    return messages;
  }

  public getMessageBypath(path: string) {
    return this.errorMessages.get(path);
  }
}

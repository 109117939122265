import * as React from 'react';

const presenterContext = React.createContext<any>(null);

// Presenter 上下文
export const PresenterProvider = presenterContext.Provider;
export const PresenterConsumer = presenterContext.Consumer;

// loading上下文
const loadingContext = React.createContext(false);
export const LoadingProvider = loadingContext.Provider;
export const LoadingConsumer = loadingContext.Consumer;
import {FormMode} from '@src/bizForm/formMode';
import {BizListPresenter} from '@src/bizList/bizListPresenter';
import {RouteUtils} from '@src/router/routeUtils';

export const EditAction = {
  key: 'edit',
  label: '编辑',
  options: {
    onClick: (presenter: BizListPresenter, data: any) => {
      RouteUtils.toForm({
        entityName: presenter.entityName,
        mode: FormMode.Edit,
        id: data.id
      });
    }
  }
};

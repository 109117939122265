import {QUERY_SOLUTION_PREFIX} from "@src/bizForm/modules/listInitiModule";
import {QuerySolution} from "@src/bizList/core/querySolution";
import {QuerySolutionHelper} from "@src/bizList/core/querySolutionHelper";
import {IQueryColumn} from "@src/interfaces/queryColumn";
import {IQuerySchema, IQuerySchemaItem} from "@src/interfaces/queryScheme";
import OperatorStore from "@src/operator";
import localforage from "localforage";
import {makeAutoObservable, observable} from "mobx";
import {LoadingStatus} from "../LoadingContainer";
import {QueryColumn, QueryColumnUtils} from "./ListSolution/queryColumn";
import {QuerySchema, QuerySchemaUtils} from "./ListSolution/querySchema";

export interface IListSolutionOptions {
  entityName: string;
  queryColumn: IQueryColumn;
  querySchema: IQuerySchema;
}

export class ListSolutionPresenter {
  public entityName: string;

  public queryColumn: QueryColumn;

  public querySchema: QuerySchema;

  public querySolution: QuerySolution;

  public querySchemaItems: Array<IQuerySchemaItem> = [];

  public loadingStatus = LoadingStatus.Loading;

  constructor(private options: IListSolutionOptions) {
    makeAutoObservable(this);
    this.init();
  }

  private async init() {
    const {values = {}, valueTypes} =
      (await localforage.getItem(
        `${OperatorStore.getID()}_${this.options.querySchema.id}_${QUERY_SOLUTION_PREFIX}options`,
      )) || {};

    this.entityName = this.options.entityName;
    this.querySchemaItems = this.options.querySchema.items;
    this.queryColumn = QueryColumnUtils.create(this.options.queryColumn);
    this.querySchema = QuerySchemaUtils.create(this.options.querySchema);
    this.querySolution = QuerySolutionHelper.createQuerySchema({
      querySchema: this.options.querySchema,
      options: {
        values,
        valueTypes,
      },
    });
    this.loadingStatus = LoadingStatus.Complete;
  }

  public toJSON() {
    return {
      entityName: this.entityName,
      queryColumn: this.queryColumn,
      querySchema: this.querySchema,
      querySolution: this.querySolution,
    };
  }
}

import {http} from './http';

export function fetchFormData (id: string, entityName: string) {
  return http({
    url: '/graphql',
    method: 'post',
    data: {
      id,
      entityName
    }
  });
}
export function postFormData (data = {}) {
  return http({
    url: '/bizForm',
    method: 'post',
    data
  });
}

export function fetchFormListData (data = {}) {
  return http({
    url: '/bizForm/list',
    method: 'post',
    data
  });
}

export function fetchFormAction (data = {}) {
  return http({
    url: '/bizForm/action',
    method: 'post',
    data
  });
}
// 导出文件
export function formExport (data: {entityName: string, id: string}) {
  return http({
    url: '/export',
    method: 'post',
    data
  });
}
import {CreateAction} from './CreateAction';
import {QueryAction} from './QueryAction';
import { RefreshAction } from './RefreshAction';

export default {
  CreateAction,
  QueryAction,
  RefreshAction,
};

export const DefaultListActions = [ CreateAction, QueryAction, RefreshAction ];

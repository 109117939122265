import {BIZ_AUTH} from '@src/common/constants/token';
import axios from 'axios';
import localforage from 'localforage';
import HandleError from './error';
import { ResponseType } from './types';

const isDevelopment = process.env.NODE_ENV === 'development';
// export const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:10443/v1' : 'https://api.vwood.xyz/v1';
export const baseURL = 'https://api.vwood.xyz/v1';

export async function httpBase () {
  const authInfo = await localforage.getItem(BIZ_AUTH) as any;
  return axios.create({
    baseURL: isDevelopment ? '/api' : baseURL,
    timeout: 60000,
    headers: {
      'content-type': 'application/json',
      token: authInfo?.token,
      tenantId: authInfo?.tenantId,
    }
  });
}

export async function http (data: any): Promise<ResponseType> {
  const instance = await httpBase();

  instance.interceptors.response.use(
    function (response) {
      return response.data;
    },
    function (error) {
      HandleError(error.response);
      return Promise.reject(error);
    },
  );

  return instance(data) as any;
}

// export function localHttp (data: any) {
//   const instance = httpBase();

//   instance.interceptors.response.use(
//     function (response) {
//       return response.data;
//     },
//     function (error) {
//       return Promise.reject(error);
//     },
//   );

//   return instance(data) as any;
// }

export const getCommentAccesskey = function () {
  if (process.env.NODE_ENV === 'development') {
    return 'e84e5603-3a1b-49ee-b4bb-97c18be9f8c0';
  }
  return '175d9560-abc3-4dac-8806-499e2853ccd9';
};

export const originUrl = process.env.NODE_ENV !== 'production' ? 'http://localhost:4000' : 'https://vwood.xyz';

export const urlExcludeProtocal = () => {
  const {search, host, pathname} = window.location;
  const domain = host.replace(/www\./, '');
  return search ? `${ domain }${ pathname }?${ search }` : domain + pathname;
};

import {BizFormField} from "@src/bizForm/core/model/bizFormField";
import {FormMode} from "@src/bizForm/formMode";
import {MSTFormField} from "@src/MST-Form/MSTFormField";
import {Col, Form} from "antd";
import {observer} from "mobx-react";
import * as React from "react";
import styles from "./style.module.scss";

export interface FormFieldElementProps {
  key: string;
  path: string;
  field: MSTFormField;
  model: BizFormField;
  mode: FormMode;
  enumType?: string;
  referType?: string;
  presenter?: any;
}

const COL_SPAN_STEP = 6;

function getColSpan(colSpan: number = 1) {
  return colSpan * COL_SPAN_STEP;
}

@observer
export class FormFieldElement extends React.Component<FormFieldElementProps> {
  private getValuePropName = (bizType: string) => {
    // return bizType === 'bool' ? 'checked' : 'value'
    return "value";
  };

  private getProps = (): {
    label: string;
    path: string;
    valuePropName: string;
    required: boolean;
    mode: FormMode;
    colSpan: number;
  } => {
    const {
      model: {colSpan, path, bizType},
      model,
      field,
      mode,
    } = this.props;

    return {
      label: model.label || (field && field.label),
      path,
      valuePropName: this.getValuePropName(bizType),
      required: field && typeof field.required !== "undefined" ? field.required : model.required,
      mode,
      colSpan,
    };
  };

  render() {
    const {children, field} = this.props;
    const {colSpan, label, path, valuePropName, required} = this.getProps();
    return (
      <Col span={getColSpan(colSpan)}>
        <Form.Item
          label={label}
          name={path}
          className={styles["item-wrapper"]}
          valuePropName={valuePropName}
          required={required}
          initialValue={field ? field.value : undefined}
        >
          {typeof children === "function" ? children(this.props) : children}
        </Form.Item>
      </Col>
    );
  }
}

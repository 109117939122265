import {BizFormField} from '@src/bizForm/core/model/bizFormField';
import {MSTFormField} from '@src/MST-Form/MSTFormField';
import * as React from 'react';
import _get from 'lodash/get';
import {ImageViewer} from '../ImageViewer';
import {IBizFormComponentOProps} from '../interface';
import {UrlViewer} from '../UrlViewer';
import {DatetimeViewer} from '../DatetimeViewer';
import {TextViewer} from '../TextViewer';
import {Checkbox} from '../Checkbox';
import {CheckboxViewer} from '../CheckboxViewer';
import {ReferViewer} from '../ReferViewer';
import {CarryViewer} from '../CarryViewer';
import {metadata} from '@src/metadata/metadata';
import {MarkdownViewer} from '../MarkdownViewer';
import TagsViewer from '../TagsViewer';
import EnumSelectViewer from '../EnumSelectViewer';
import { BizColumn } from '@src/bizList/core/bizList';
import CodeViewer from '../CodeViewer';

const toString = Object.prototype.toString;

export interface IEntityFieldViewer extends BizColumn {
  // model: BizFormField;
  // field: MSTFormField;
  value: any;
  // bizType: string;
  // isRefer?: boolean;
  // path: string;
  entityName: string;
}
/**
 * 文本的查看模式内容组件
 */
export const EntityFieldViewer = (props: IEntityFieldViewer) => {
  const {isRefer, entityName} = props;
  let bizType = props.bizType;
  let value = props.value;

  const getEntityField = (path: string) => {
    const entityField = metadata.getEntity(entityName).getFieldLastNode(path);
    if (!entityField) {
      console.error('path is not exist in entity:' + entityName);
      return null;
    }
    return entityField;
  };

  if (props.path.split('.').length > 1) {
    const entityField = getEntityField(props.path);
    bizType = entityField.bizType;

    if (props.value && typeof props.value === 'object') {
      value = _get(props.value, 'name');
    }
  }

  if (isRefer) {
    const entityField = getEntityField(props.path);
    bizType = entityField.bizType;
  }

  switch (bizType) {
    case 'url':
      return <UrlViewer {...props} value={value} />;
    case 'image':
      return <ImageViewer {...props} value={value} />;
    case 'datetime':
      return <DatetimeViewer {...props} value={value} />;
    case 'text':
    case 'textarea':
      return <TextViewer {...props} value={value} />;
    case 'bool':
      return <CheckboxViewer {...props} value={value} />;
    case 'refer':
      return <ReferViewer {...props} value={value} />;
    case 'markdown':
      return <MarkdownViewer {...props} value={value} />;
    case 'enum':
      return <EnumSelectViewer {...props} value={value} />;
    case 'tags':
      return <TagsViewer {...props} value={value}/>
    case 'code':
      return <CodeViewer {...props} value={value}/>
    default:
      // 携带关系
      if (toString.call(props.value) === '[object Object]') {
        return <span>{_get(props.value, 'name')}</span>;
      }
      // 默认返回
      return <TextViewer {...props} />;
  }
};

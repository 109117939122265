import { Template } from "@src/pages/devtool/bizForm/form/template";
import { Col, Form, Row } from "antd";
import { FormLayout } from "antd/lib/form/Form";
import { observable, reaction } from "mobx";
import { observer } from "mobx-react";
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { BizFormPresenter } from "../bizFormPresenter";
import { LifecycleEvent } from "../lifecycleEvent";
import { ModuleNames } from "../modules/interface";
import { MasterFrom } from "./form-zone/masterForm";

import styles from "./style/index.module.scss";

interface IBizFormBody {
  // template: Template;
  presenter: BizFormPresenter;
}

export class BizFormBody extends React.Component<IBizFormBody> {

  componentDidMount() {
    this.props.presenter.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.onFormCreated);
  }

  render() {
    return this.renderContent();
  }

  renderContent = () => {
    const {
      presenter: { template, mode },
      presenter,
    } = this.props;
    if (!template) {
      return null;
    }
    return <MasterFrom presenter={presenter} />;
  };
}

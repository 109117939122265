import { autobind } from 'core-decorators';
import { fetchMetadata, fetchTemplate } from "@src/http";
import { FormDataLoader } from "@src/http/formDataLoader";
import { Metadata } from "@src/metadata/metadata";
import { Template } from "@src/pages/devtool/bizForm/form/template";
import { BizFormPresenter } from "../bizFormPresenter";
import { LifecycleEvent } from "../lifecycleEvent";
import { BaseModule } from "./baseModule";
import { EventModule } from "./eventModule";
import { ModuleNames } from "./interface";
import { ModulePropertyWrapper, ModuleWrapper } from "./moduleManager";
import { FormMode } from '../formMode';


// 整个页面的初始化模块
@autobind
@ModuleWrapper(ModuleNames.InitModule)
export class InitModule extends BaseModule {

  @ModulePropertyWrapper(ModuleNames.Presenter) private presenter: BizFormPresenter
  @ModulePropertyWrapper(ModuleNames.EventModule) private eventModule: EventModule

  public apply() {
    this.eventModule.on(LifecycleEvent.onModuleReady, this.loadData)
  }

  // 加载tempalte、metadata、formData数据
  private async loadData() {
    const { presenter } = this;
    // @TODO: 考虑抽离出去
    const [metadata, template] = await Promise.all([
      fetchMetadata(presenter.entityName),
      fetchTemplate(presenter.entityName),
    ]);
    // 获取form表单数据

    presenter.metadata = metadata as any;
    presenter.template = template as any;
    if (presenter.mode === FormMode.Create) {
      presenter.initFormData = {};
    } else {
      const formDataLoader: FormDataLoader = new FormDataLoader({
        entityName: presenter.entityName,
        presenter,
        // @TODO: 暂时写死
        id: presenter.id,
      });

      await formDataLoader.query();

      presenter.initFormData = formDataLoader.data || {};
    }

    this.eventModule.dispatch(LifecycleEvent.onLoadedInitData);
  }
}

import * as React from 'react';
import {RouterProps} from 'react-router';
import {BizForm} from '../bizForm/bizForm';
import {BizFormPresenter, IBizFormPresenterOptions} from '../bizForm/bizFormPresenter';
import {FormMode} from '../bizForm/formMode';
import _get from 'lodash/get';
import {Observer} from 'mobx-react';

// 通过业务对象生成对应的表单路由
interface IBizFormRoute {
  entityName: string;
  createPresenter: (options?: any) => BizFormPresenter;
}
export const bizFormRoute = (options: IBizFormRoute) => {
  const {entityName, createPresenter} = options;
  const routes = [
    {
      exact: true,
      sensitive: true,
      path: `/${ entityName }/${ FormMode.View }/:formId`,
      mode: FormMode.View
    },
    {
      exact: true,
      sensitive: true,
      path: `/${ entityName }/${ FormMode.Copy }/:formId`,
      mode: FormMode.Copy
    },
    {
      exact: true,
      sensitive: true,
      path: `/${ entityName }/${ FormMode.Edit }/:formId`,
      mode: FormMode.Edit
    },
    {
      exact: true,
      sensitive: true,
      path: `/${ entityName }/${ FormMode.Create }`,
      mode: FormMode.Create
    }
  ];

  return routes.map((route) => ({
    ...route,
    render: (props?: any) => {
      return (
        <Observer>
          {() => (
            <BizForm
              key={route.path}
              id={_get(props, 'match.params.formId')}
              mode={route.mode}
              presenter={
                createPresenter({
                  ...options,
                  mode: route.mode
                }) as any
              }
            ></BizForm>
          )}
        </Observer>
      );
    }
  }));
};

import * as React from 'react';
import {Input as AntdInput} from 'antd';
import {IBizFormComponentOProps} from '../interface';

interface IInput extends IBizFormComponentOProps {}

export function Input (props: IInput) {
  const {onChange = () => {}, value = '', ...reset} = props;
  const handleChange = (e: any) => {
    onChange(e.target.value);
  };
  const handleBlur = (e: React.FocusEvent): void => {
    if (typeof value ==="string") {
      onChange(value?.trim());
    } else {
      onChange(value);
    }
  };
  return <AntdInput {...reset} value={value} onChange={handleChange} onBlur={handleBlur} />;
}

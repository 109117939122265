import {TimePicker as AntTimePicker} from 'antd';
import moment from 'moment';
import {useCallback} from 'react';
import {IBizFormComponentOProps} from '../interface';

export const TimePicker = (props: IBizFormComponentOProps) => {
  const onChange = useCallback((_, timeString: string) => {
    props.onChange && props.onChange(timeString);
  }, []);

  return (
    <AntTimePicker
      value={moment(props.value)}
      onChange={onChange}
      disabled={props.disabled}
    />
  );
};

import {IMetadata} from '@src/interfaces/metadata';
import {Entity} from '@src/metadata/entity';
import {metadata} from '@src/metadata/metadata';
import {Metadata} from '@src/pages/devtool/bizForm/form/metadata';
import {Template} from '@src/pages/devtool/bizForm/form/template';
import {BizFormField} from './bizFormField';

/**
 * 前端数据模型, 用于展示使用
 */
export class BizForm {
  public entity: Entity; // 基础数据模型
  public fields: BizFormField[];
  public fieldsMap: {[key: string]: BizFormField} = {};

  public getField (key: string) {
    return this.fieldsMap[ key ];
  }

  constructor (private entityName: string, private formMetadata: IMetadata, private formTemplate: Template) {
    this.entity = metadata.getEntity(entityName);
    this.init();
  }

  init () {
    const {master = [], childTables = []} = this.formTemplate;
    const columns = [ ...master, ...childTables ].map((table) => table.columns).flat(1);
    this.fields = columns.map((templateCol) => {
      const metadataItem = this.formMetadata.items.find((item) => item.path === templateCol.path);
      const formField = new BizFormField(this.entityName, templateCol, metadataItem);

      this.fieldsMap[ templateCol.path ] = formField;
      // 如果携带关系的跟业务对象没有在map中，那么需要补充进去，因为mstForm的取值过程是从根业务对象开始的
      if (formField.isCarry && !this.fieldsMap[ templateCol.path.split('.')[ 0 ] ]) {
        const rootPath = templateCol.path.split('.')[ 0 ];
        const rootMetadataItem = this.formMetadata.items.find((item) => item.path === rootPath);
        const rootFormField = new BizFormField(this.entityName, templateCol, rootMetadataItem);
        this.fieldsMap[ rootPath ] = rootFormField;
      }

      return formField;
    });
  }
}

import {
  ENUM_ValueType_all_in,
  ENUM_ValueType_all_nin,
  ENUM_ValueType_between,
  ENUM_ValueType_in,
  ENUM_ValueType_nin
} from '@packages/gen/constants/enums/ValueType';

export class ValueTypeUtils {
  public static isMutil (valueType: string): boolean {
    return [
      ENUM_ValueType_between,
      ENUM_ValueType_in,
      ENUM_ValueType_nin,
      ENUM_ValueType_all_in,
      ENUM_ValueType_all_nin
    ].includes(valueType);
  }
}

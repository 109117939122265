import {BizFormPresenter} from '@src/bizForm/bizFormPresenter';
import {Observer} from 'mobx-react';
import * as React from 'react';
import {BizFormField} from '@src/bizForm/core/model/bizFormField';
import {MSTFormField} from '@src/MST-Form/MSTFormField';
import {LoadingStatus} from '@src/components/LoadingContainer';
import Refer from '../Refer';

interface IReferSelect {
  entityName: string; // 业务对象名称
  value: any; // 传入的值
  presenter: BizFormPresenter;
  onChange: (value: any) => void; // 选择数据时触发
  model: BizFormField;
  field: MSTFormField;
  disabled?: boolean;
  isMutil?: boolean; //多选
  id?: string;
}

/**
 * 根據entityName自動查詢对应的数据
 * 系统里可以作为refer的数据都应该有id、title字段。ReferSelect组件读取对应的属性即可
 * refer只显示entity本身的字段，子表的字段不会显示
 * ReferSelect应作为受控组件使用
 * @param props
 * @returns
 */
function ReferSelect (props: IReferSelect) {
  const {presenter, model, field, value} = props;
  const entityName = model.referType;
  const logicPath = field.path;
  const getName = React.useCallback((path: string) => {
    const prefixEntityLen = logicPath.split('.').length;
    return path.split('.').slice(prefixEntityLen).join('');
  }, []);
  const queryFields = presenter
    .getFields(logicPath)
    .map((field) => getName(field.path))
    .filter((fileName) => !!fileName);

  const onChange = (value: any) => {
    props?.onChange(value);
  };
  return (
    <Observer>
      {() => (
        <Refer
          id={props.id}
          entityName={entityName}
          queryFields={queryFields}
          value={props.value}
          onChange={onChange}
          disabled={props.disabled}
        />
      )}
    </Observer>
  );
}

export default ReferSelect;

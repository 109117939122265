import {BIZ_AUTH} from '@src/common/constants/token';
import {Auth} from '@src/interfaces/auth';
import localforage from 'localforage';
import {useEffect, useState} from 'react';

interface AuthCheckProps {
  children: JSX.Element;
}

export default function AuthCheck ({children}: AuthCheckProps): JSX.Element {
  const [ status, setStatus ] = useState(false);
  // 校验token是否存在
  const authCheck = async () => {
    const authInfo: Auth = await localforage.getItem(BIZ_AUTH);
    if (!authInfo?.token) {
      window.open('/login', '_self');
    } else {
      setStatus(true);
    }
  };

  useEffect(() => {
    authCheck();
  }, []);

  if (status === true) {
    return children;
  }

  return null;
}
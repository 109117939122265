import {DataLoader, IDataloaderOptions} from '../dataLoader/dataLoader';
import _get from 'lodash/get';
import {BizListPresenter} from '@src/bizList/bizListPresenter';
import {BizFormListQueryParams, BizFormListQueryValue} from '@src/interfaces/bizFormListQuery';
import {fetchFormListData} from '../formData';
import {observable, runInAction} from 'mobx';
import {getDefaultListData} from '@src/bizList/interface';
import { message } from 'antd';

export interface IListDataLoader extends IDataloaderOptions {
  // logicPath: string;
  entityName: string;
  presenter: BizListPresenter;
  queryStr?: string;
  // querySolution: QuerySolution;
}

/**
 * 列表使用
 */
export class ListDataLoader extends DataLoader {
  // 列表数据
  // @observable
  public override data = observable(getDefaultListData());

  private presenter: BizListPresenter;

  constructor (options: IListDataLoader) {
    super(options);
    this.presenter = options.presenter;
    this.init();
    this.initQueryBuilder();
  }

  init () {
    this.queryOptions.queryFields = this.presenter.model.queryColumns;
  }

  public async query () {
    const {entityName} = this.options;
    const values: Array<BizFormListQueryValue> = this.presenter.querySolution.items
      .filter((item) => {
        if (!Array.isArray(item.values)) {
          return false;
        }
        return item.values.filter((value) => value !== '' && value !== null && typeof value !== 'undefined').length > 0;
      })
      .map((item) => ({path: item.path, valueType: item.valueType, values: item.values}));
    const params: BizFormListQueryParams = {
      entityName: entityName,
      queryStr: this.queryBuilder.criteriaStr,
      columns: this.queryOptions.queryFields,
      values: values,
      pageSize: this.presenter.listData.pageSize,
      pageNo: this.presenter.listData.pageNo,
      sorts: [
        {
          name: 'updateTime',
          isDesc: true
        },
        {
          name: 'createTime',
          isDesc: true
        }
      ]
    };

    try {
      return await fetchFormListData(params).then((res) => {
        //@ts-ignore
        const {data, success, errMsg} = res;
        if (success) {
          runInAction(() => {
            this.data.data = data.data;
            this.data.count = data.count;
            this.data.pageSize = data.pageSize;
            this.data.pageNo = data.pageNo;
            this.data.hasNextPage = data.hasNextPage;
          });
        }
      });
    } catch (err) {
      console.error(err);
    }
  }
}

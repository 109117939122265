import {EditAction} from './EditAction';
import {ViewAction} from './ViewAction';
import {DeleteAction} from './DeleteAction';

export default {
  ViewAction,
  EditAction,
  DeleteAction
};

export const DefaultListRowActions = [ ViewAction, EditAction, DeleteAction ];

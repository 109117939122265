import * as React from "react";
import CodeEditor from "../CodeEditorBase";
// import {Controlled as CodeMirror} from 'react-codemirror2';
// import 'codemirror/lib/codemirror.css';
import "./style.scss";

interface IMarkdownEditor {
  value: string;
  onChange: (val: string) => void;
}

const options = {
  mode: "xml",
  theme: "material",
  lineNumbers: true,
};

export const MarkdownEditor = (props: IMarkdownEditor) => {
  return (
    <div className="markdown-editor">
      <CodeEditor value={props.value} onChange={props.onChange} lang="markdown" />
    </div>
  );
};

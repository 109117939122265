export default function componentsMap(bizType: string) {
  switch (bizType) {
    case 'string':
      return 'Text';
    case 'textarea':
      return 'Textarea';
    case 'count':
      return 'Count';
    case 'numeric':
      return 'Numeric';
    case 'date':
      return 'Date';
    case 'time':
      return 'Time';
    case 'refer':
      return 'ReferSelect';
    case 'enum':
      return 'EnumSelect';
    case 'amount':
      return 'Amount'; // 金额
    case 'image':
      return 'Image';
    case 'url':
      return 'Url';
    case 'datetime':
      return 'Datetime';
    case 'bool':
      return 'Checkbox';
    case 'markdown':
      return 'Markdown';
    case 'tags':
      return "TagsEditor";
    case "code":
      return "Code";
    default:
      return 'Text';
  }
}

import {BizListPresenter} from "@src/bizList/bizListPresenter";
import {message} from "antd";
/**
 * 列表行使用
 */
export const EmbedCopy = {
  key: "embedCopy",
  label: "embed",
  options: {
    onClick: (presenter: BizListPresenter, data?: any) => {
      const code = `
            <iframe height="300" style="width: 100%;" scrolling="no" 
            title="Reveal you Star with cool hover effect" 
            src="https://vwood.xyz/tiny-code/embed/${data.id}" 
            frameborder="no" loading="lazy" allowtransparency="true" allowfullscreen="true">
            </iframe>
            `;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(code);
      }
      message.success("复制成功");
    },
  },
};

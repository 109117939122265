//https://www.yuque.com/u183495/kb/gmx2xc/edit
export enum ValueType {
  eq = 'ValueType.eq',
  ne = 'ValueType.ne',
  gt = 'ValueType.gt',
  lt = 'ValueType.lt',
  ge = 'ValueType.ge',
  le = 'ValueType.le',
  between = 'ValueType.between',
  like = 'ValueType.like',
  in = 'ValueType.in', //多值，在指定选项内
  nin = 'ValueType.nin', //多值，不在指定选项内
}

export const singleValuetype = [
  ValueType.eq,
  ValueType.ne,
  ValueType.gt,
  ValueType.ge,
  ValueType.lt,
  ValueType.le,
  ValueType.like
];

export const mutilValueType = [ ValueType.between, ValueType.in, ValueType.nin ];

export const valueTypeMaps: {[key: string]: Array<ValueType>} = {
  enum: [ ValueType.eq, ValueType.ne, ValueType.in, ValueType.nin ],
  email: [ ValueType.eq, ValueType.ne, ValueType.like ],
  text: [ ValueType.eq, ValueType.ne, ValueType.like ],
  textarea: [ ValueType.eq, ValueType.ne, ValueType.like ],
  markdown: [ ValueType.eq, ValueType.ne, ValueType.like ],
  url: [ ValueType.eq, ValueType.ne, ValueType.like ], // url地址
  count: [
    // 整数
    ValueType.eq,
    ValueType.ne,
    ValueType.gt,
    ValueType.ge,
    ValueType.lt,
    ValueType.le,
    ValueType.between
  ],
  numeric: [
    // 浮点数
    ValueType.eq,
    ValueType.ne,
    ValueType.gt,
    ValueType.ge,
    ValueType.lt,
    ValueType.le,
    ValueType.between
  ],
  refer: [ ValueType.eq, ValueType.ne, ValueType.in, ValueType.nin ], //  外键
  bool: [ ValueType.eq, ValueType.ne ], // bool
  datetime: [
    // 时间
    ValueType.eq,
    ValueType.ne,
    ValueType.gt,
    ValueType.ge,
    ValueType.lt,
    ValueType.le,
    ValueType.between,
    ValueType.in,
    ValueType.nin
  ],
  date: [
    // 日期
    ValueType.eq,
    ValueType.ne,
    ValueType.gt,
    ValueType.ge,
    ValueType.lt,
    ValueType.le,
    ValueType.between,
    ValueType.in,
    ValueType.nin
  ]
};

export interface IvalueTypeOption {
  value: ValueType;
  label: string;
}
export const valueTypeOptions = [
  {
    value: ValueType.eq,
    label: '等于'
  },
  {
    value: ValueType.ne,
    label: '不等于'
  },
  {
    value: ValueType.gt,
    label: '大于'
  },
  {
    value: ValueType.lt,
    label: '小于'
  },
  {
    value: ValueType.ge,
    label: '大于等于'
  },
  {
    value: ValueType.le,
    label: '小于等于'
  },
  {
    value: ValueType.between,
    label: '区间'
  },
  {
    value: ValueType.like,
    label: '包含'
  },
  {
    value: ValueType.in,
    label: '多值'
  },
  {
    value: ValueType.nin,
    label: '多值排除'
  }
];

export const getValueTypeOptions = (type: string): Array<IvalueTypeOption> => {
  const types = valueTypeMaps[ type ];
  if (!types) {
    return [];
  }

  return types.map((type) => valueTypeOptions.find((option) => option.value === type));
};

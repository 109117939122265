import EN_Workflow from '@packages/gen/types/entities/Workflow';
import {IBizAction} from '@src/bizAction/interface';
import {BizListPresenter} from '@src/bizList/bizListPresenter';
import {BizListConfig} from '@src/bizList/interface';
import { RowView } from './actions/RowView';

export class WorkflowListPresenter<T = EN_Workflow> extends BizListPresenter<T> {
  public listBizConfig (config: BizListConfig<T>): BizListConfig<T> {
    config.column = {
      name: {
        cellRender: (val) => {
          return <span style={{color: 'red'}}>{val}</span>;
        }
      }
    };

    config.listRowActionManager.use((actions: Array<IBizAction>, data: any): Array<IBizAction> => {
      const viewIndex = actions.findIndex(action => action.key === "view");
      if (viewIndex !== -1) {
        actions.splice(viewIndex, 1, RowView);
      }
      return actions;
    });
    return config;
  }
}

import * as React from 'react';
import {FormMode} from '../bizForm/formMode';
import _get from 'lodash/get';
import {BizList} from '@src/bizList/bizList';
import {BizListPresenter} from '@src/bizList/bizListPresenter';

// 通过业务对象生成对应的表单路由
interface IBizListRoute {
  entityName: string;
  path?: string;
  createPresenter: (options?: any) => BizListPresenter;
}
export const bizListRouter = (options: IBizListRoute) => {
  const {entityName, createPresenter, path} = options;
  const routes = [
    {
      exact: true,
      sensitive: true,
      path: path ? path : `/${ entityName }-list`,
      mode: FormMode.View
    }
  ];

  return routes.map((route) => ({
    ...route,
    render: (props?: any) => {
      return <BizList key={route.path} presenter={createPresenter(options) as any}></BizList>;
    }
  }));
};

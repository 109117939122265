import {DataLoader, IDataloaderOptions} from "../dataLoader/dataLoader";
import {graphqlAllData} from "../graphql";
import _get from "lodash/get";
import {LoadingStatus} from "@src/components/LoadingContainer";
import {observable} from "mobx";

export interface IReferDataLoader extends IDataloaderOptions {}

/**
 * 列表使用
 */
export class ReferDataLoader extends DataLoader {
  public data = observable.array([], {deep: false});

  constructor(options: IReferDataLoader) {
    super(options);
    this.init();
    this.initQueryBuilder();
  }

  init() {
    this.queryOptions.queryFields = this.options.queryFields || [];
  }

  public async query() {
    const {entityName} = this.options;
    const _params = {
      query: this.queryBuilder.toString(),
    };
    try {
      this.loadingStatus = LoadingStatus.Loading;
      await graphqlAllData(_params).then((res) => {
        //@ts-ignore
        const {data = [], success} = res;
        if (success) {
          this.data.replace(data ? data : []);
        }
        this.loadingStatus = LoadingStatus.Complete;
      });
    } catch (err) {
      console.error(err);
      this.loadingStatus = LoadingStatus.Failed;
    }
  }
}


// 邮件类型
enum ENUM_EmailType {
    
      // 普通邮件
      Normal = "EmailType.normal",
    
      // 模版邮件
      Template = "EmailType.template",
    
}
export default ENUM_EmailType;

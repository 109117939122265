import {IBizAction, IBizActionManager, useCallback} from "./interface";

export class BaseBizActionManager<T> implements IBizActionManager<T> {
  defaultActions: Array<IBizAction> = [];

  // 最终要渲染的actions
  renderActions: Array<IBizAction> = [];

  useCallbackList: Array<useCallback<T>> = [];

  constructor(defaultActions?: Array<IBizAction>) {
    this.defaultActions = defaultActions || [];
  }

  public use(func: useCallback<T>) {
    if (typeof func === "function") {
      this.useCallbackList.push(func);
    } else {
      throw new Error("func is not a function");
    }
  }

  public execute(data?: T) {
    return this.useCallbackList.reduce(
      (prev, func) => {
        return func(prev, data);
      },
      [...this.defaultActions],
    );
  }

  // public addAction(action: IBizAction, insertIndex?: number): void {
  //   this.defaultActions.splice(insertIndex, 0, action);
  // }

  // public removeAction(actionKey: string) {
  //   this.defaultActions
  // }
}

import * as React from 'react';
import { IBizFormComponentOProps } from '../interface';

export interface IUrlViewer{
    value: string;
}

export const UrlViewer = (props: IUrlViewer) => {
    let value = props.value;

    if (!value) {
        return null;
    }
    // 格式化url，
    // 1: 检查url是否含有schema + //, 没有就加上//
    if (!(value.startsWith("https://") || value.startsWith("http://"))) {
        value = "//" + value;
    }
    return <a href={value} target="_blank">{props.value}</a>
}
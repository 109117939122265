import {IType} from 'mobx-state-tree';
import {EntityField} from '@src/metadata/entity';
import {types} from './types';
import {metadata} from '@src/metadata/metadata';
import {FieldOptions, MSTForm} from './MSTForm';
import {BizForm} from '@src/bizForm/core/model/bizForm';
import {ValidatorUtils} from './validators/validatorUtils';

export interface MSTFormHelperOptions {
  fields: Array<string>;
  fieldOptions: {[fieldName: string]: FieldOptions};
  initValue?: object;
  // validators?: Array<IValidator>; // 检验期后面添加
}

// MSTForm 实例构建类
export class MSTFormHelper {
  public static createForm (entityName: string, options: MSTFormHelperOptions) {
    const {initValue} = options;
    const entityModel = MSTFormHelper.createModel(entityName, options);
    const mstModel = entityModel.create(initValue || {});

    const MSTFormOptions = {
      fieldOptions: options.fieldOptions
      // validators: options.validators,
    };

    return MSTForm.create(mstModel, MSTFormOptions);
  }

  public static createModelTypes (entityName: string, fields: Array<string> = []) {
    const modelTypes: {[key in string]: IType<any, any, any>} = {};
    const entity = metadata.getEntity(entityName);

    fields.forEach((fieldName) => {
      const entityField = entity.getField(fieldName);
      if (!entityField) {
        console.error(`field '${ fieldName }' is not exist with the entity '${ entityName }'`);
        return;
      }
      const typeConstructor = MSTFormHelper.createTypeConstructor(entityField);

      modelTypes[ fieldName ] = typeConstructor;
    });

    return modelTypes;
  }

  public static createModel (entityName: string, options: MSTFormHelperOptions) {
    const modelTypes = MSTFormHelper.createModelTypes(entityName, options.fields);
    return types
      .model(modelTypes)
      .views((self) => ({
        getValue (path: string) {
          return self[ path ];
        }
      }))
      .actions((self) => ({
        setValue (path: string, value: any) {
          self[ path ] = value;
        }
      }));
  }

  // 字段属性的类型
  public static createTypeConstructor (field: EntityField) {
    let typeConstructor: IType<any, any, any> = types.optional(types.string, '');

    // 是否为外键
    if (field.isMutil) {
      typeConstructor = types.array(MSTFormHelper.getPrimitiveType(field.type));
    } else if (field.isRefer) {
      // refer
      typeConstructor = MSTFormHelper.getPrimitiveType('refer');
    } else {
      // 其他类型
      typeConstructor = MSTFormHelper.getPrimitiveType(field.type);
    }

    return typeConstructor;
  }

  public static getPrimitiveType (type: string) {
    let typeConstructor: IType<any, any, any> = types.optional(types.string, '');

    switch (type) {
      case 'refer':
        typeConstructor = types.refer;
        break;
      case 'string':
        typeConstructor = types.optional(types.string, '');
        break;
      case 'bool':
        typeConstructor = types.optional(types.boolean, false);
        break;
      case 'int64':
      case 'uint64':
      case 'int32':
      case 'uint32':
      case 'int8':
      case 'uint8':
      case 'int':
      case 'uint':
        typeConstructor = types.optional(types.integer, 0);
        break;
      case 'float':
        typeConstructor = types.optional(types.number, 0);
        break;
      default:
        typeConstructor = types.optional(types.string, ''); // 默认字符串
    }
    return typeConstructor;
  }

  public static addRequiredValidatror (form: MSTForm, fieldNames: string[], model: BizForm) {
    fieldNames.forEach((fieldName) => {
      const field = model.getField(fieldName);
      if (field && field.required) {
        form.addValidator(fieldName, ValidatorUtils.required(field.label));
      }
    });
  }
}

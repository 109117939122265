import {LifecycleEvent} from '@src/bizForm/lifecycleEvent';
import {ModuleNames} from '@src/bizForm/modules/interface';
import {BizListPresenter} from '@src/bizList/bizListPresenter';
import {TDeleteResponse} from '@src/interfaces/response';
import FormUtils from '@src/utils/formUtils';
import {message} from 'antd';

export const DeleteAction = {
  key: 'delete',
  label: '删除',
  options: {
    confirm: true,
    onClick: async (presenter: BizListPresenter, data: any) => {
      try {
        const result = (await FormUtils.FormActionDelete(presenter.entityName, data.id)) as TDeleteResponse;
        if (result.data) {
          presenter.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.queryListData);
        } else {
          message.error(result.errMsg);
        }
      } catch (err) {
        message.error(err);
      }
    }
  }
};

import { ProductFormPresenter, ProductListPresenter } from "@src/pages/product";
import { bizFormRoute } from "../bizFormRouter";
import { bizListRouter } from "../bizListRouter";

export default [
  ...bizFormRoute({
    entityName: "Product",
    createPresenter: (options: any) => {
      // const { ProductFormPresenter } = await import(
      //   "@src/pages/product/productFormPresenter"
      // );
      // return new ProductFormPresenter(options);

      return new ProductFormPresenter(options);
    },
  }),
  ...bizListRouter({
    entityName: "Product",
    createPresenter: (options: any) => {
      return new ProductListPresenter(options);
    },
  })
];

import {BizListPresenter} from "@src/bizList/bizListPresenter";
import IconFont from "@src/components/Iconfont";
import {Button, Dropdown, MenuProps, Popconfirm} from "antd";
import * as React from "react";
import {IBizAction} from "../interface";
import styles from "./style.module.scss";

function BizAction(props: IBizAction & {presenter: BizListPresenter; data: any}) {
  const {label, options, presenter, data} = props;

  if (options.confirm) {
    return (
      <Popconfirm
        title={`是否确认 “${label}”`}
        onConfirm={() => options.onClick && options.onClick(presenter, data)}
        okText="确定"
        cancelText="取消"
      >
        <Button type="link" style={{marginRight: "8px"}} size="small" {...options} onClick={() => {}}>
          {label}
        </Button>
      </Popconfirm>
    );
  }

  return (
    <Button
      type="link"
      style={{marginRight: "8px"}}
      size="small"
      {...options}
      onClick={() => options.onClick && options.onClick(presenter, data)}
    >
      {label}
    </Button>
  );
}

interface ListRowActionBarProps {
  presenter: BizListPresenter;
  data: any;
}

export class ListRowActionBar extends React.Component<ListRowActionBarProps> {
  render() {
    const {presenter, data} = this.props;
    const actions = presenter.config.listRowActionManager.execute(data);
    let displayActions = actions;
    let moreActions: MenuProps["items"] = [];
    if (actions.length > 3) {
      displayActions = actions.slice(0, 3);
      moreActions = actions
        .slice(3)
        .map((action) => ({
          key: action.key,
          label: <BizAction key={action.key} presenter={presenter} data={data} {...action} />,
        }));
    }
    return (
      <div className={styles["actionbar"]}>
        {displayActions.map((action) => (
          <BizAction key={action.key} presenter={presenter} data={data} {...action} />
        ))}
        {moreActions.length ? (
          <Dropdown
            menu={{
              items: moreActions,
            }}
          >
            <Button type="link" size="small">
              <IconFont type="icon-more_light"></IconFont>
            </Button>
          </Dropdown>
        ) : null}
      </div>
    );
  }
}

import {BizFormPresenter} from '@src/bizForm/bizFormPresenter';
import {FormMode} from '@src/bizForm/formMode';
import {Button} from 'antd';
import * as React from 'react';
import {IBizAction} from '../interface';
import styles from "./style.module.scss";

export interface FormActionBarProps {
  presenter: BizFormPresenter;
}

export class FormActionBar extends React.Component<FormActionBarProps> {
  getActions = () => {
    const {presenter} = this.props;
    let actions = presenter.config.formActionManager.execute(presenter.form.getFormData());
    if (presenter.mode !== FormMode.View) {
      actions = actions.filter((action) => action.key !== 'edit');
    }
    return actions;
  };

  render () {
    const {presenter} = this.props;
    const actions = this.getActions();
    return (
      <div>
        {actions.map((action) => (
          <FormAction key={action.key} presenter={presenter} {...action} />
        ))}
      </div>
    );
  }
}

function FormAction (props: IBizAction & {presenter: BizFormPresenter}) {
  const {label, options, presenter} = props;
  return (
    <Button size="small" className={styles["formAction-item"]} {...options} onClick={() => options.onClick && options.onClick(presenter)}>
      {label}
    </Button>
  );
}

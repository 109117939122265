
// ValueType 

// 等于 
export const ENUM_ValueType_eq = "ValueType.eq"; 

// 不等于 
export const ENUM_ValueType_ne = "ValueType.ne"; 

// 大于 
export const ENUM_ValueType_gt = "ValueType.gt"; 

// 小于 
export const ENUM_ValueType_lt = "ValueType.lt"; 

// 大于等于 
export const ENUM_ValueType_ge = "ValueType.ge"; 

// 小于等于 
export const ENUM_ValueType_le = "ValueType.le"; 

// 区间 
export const ENUM_ValueType_between = "ValueType.between"; 

// 模糊匹配 
export const ENUM_ValueType_like = "ValueType.like"; 

// 包含 
export const ENUM_ValueType_in = "ValueType.in"; 

// 不包含 
export const ENUM_ValueType_nin = "ValueType.nin"; 

// 全部包含 
export const ENUM_ValueType_all_in = "ValueType.all_in"; 

// 全部不包含 
export const ENUM_ValueType_all_nin = "ValueType.all_in"; 


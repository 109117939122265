import {IBizAction} from '@src/bizAction/interface';
import {Button} from 'antd';
import {observer} from 'mobx-react';
import * as React from 'react';
import { BizListPresenter } from '../../bizList/bizListPresenter';

export interface ListActionBarProps {
  presenter: BizListPresenter;
}

@observer
export class ListActionBar extends React.Component<ListActionBarProps> {
  render () {
    const {presenter} = this.props;
    const actions = presenter.config.listActionManager.execute()
    return (
      <div>
        {actions.map((action) => (
          <BizAction key={action.key} presenter={presenter} {...action} />
        ))}
      </div>
    );
  }
}

function BizAction (props: IBizAction & {presenter: any}) {
  const {label, options, presenter} = props;
  return (
    <Button
      style={{marginLeft: '8px'}}
      size="small"
      {...options}
      onClick={() => options.onClick && options.onClick(presenter)}
    >
      {label}
    </Button>
  );
}

import React from 'react';
import {Checkbox as AntCheckbox} from 'antd';
import {IBizFormComponentOProps} from '../interface';

export interface ICheckbox extends IBizFormComponentOProps {}

export const Checkbox = (props: ICheckbox) => {
  const handleChange = (e: any) => {
    props?.onChange(e.target.checked);
  };
  return <AntCheckbox {...props} checked={props.value} onChange={handleChange} />;
};

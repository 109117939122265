import React from "react";
import {Route} from "react-router";
import productRoutes from "./product";
import orderRoutes from "./order";
import articleRoutes from "./article";
import categoryRoutes from "./category";
import templateRoutes from "./template";
import emailRoutes from "./email";
import fileRoutes from "./file";
import workflowRoutes from "./workflow";
import operatorRoutes from "./operator";
import tinyCodeRoutes from "./tinyCode";
// import {isProd} from "@src/utils";

const CustomeRoutes = [
  ...orderRoutes,
  ...articleRoutes,
  ...categoryRoutes,
  ...workflowRoutes,
  ...operatorRoutes,
  ...tinyCodeRoutes,
  ...productRoutes,
  ...templateRoutes,
  ...emailRoutes,
  ...fileRoutes,
  // ...(isProd ? [...productRoutes, ...templateRoutes, ...emailRoutes, ...fileRoutes] : []),
].map((item) => <Route {...item} key={item.path}></Route>);

export default CustomeRoutes;

import EN_Email from '@packages/gen/types/entities/Email';
import ENUM_EmailType from '@packages/gen/types/enums/EmailType';
import {IDisposer} from '@src/MST-Form/interface';
import {MSTForm} from '@src/MST-Form/MSTForm';
import {autobind} from 'core-decorators';
import {reaction, runInAction} from 'mobx';
import {BizFormPresenter} from '../../bizForm/bizFormPresenter';

@autobind
export class EmailFormPresenter extends BizFormPresenter<EN_Email> {
  public onFormCreated (form: MSTForm, disposers: IDisposer[]) {
    disposers.push(
      reaction(
        () => form.select('type').value,
        (value) => {
          // 普通类型时不能选择模版
          if (value === ENUM_EmailType.Normal) {
            runInAction(() => {
              form.select('template').disabled = true;
              form.select('template').value = undefined;
              // 
              form.select('content').disabled = false;
            });
          } else {
            runInAction(() => {
              form.select('template').disabled = false;
              form.select('content').disabled = true;
              form.select('content').value = undefined;
            });
          }
        },
      ),
    );
  }
}

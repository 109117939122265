import { ClassBaseInterface } from "@src/common/classBase";
import { BizFormPresenter } from "../bizFormPresenter";
import { ILisenter } from "./interface";

export interface BaseModuleOptions {
    presenter: BizFormPresenter;
}

export class BaseModule implements ClassBaseInterface {

    protected listeners: Array<ILisenter> = [];

    constructor(protected options: BaseModuleOptions) {

    }

    // 模块执行函数, 
    public apply() {

    }

    public destructor() {
        // 销毁当前模块添加的监听器
        this.listeners.forEach(listener => listener && listener());
    }

}
import { BizFormPresenter } from '@src/bizForm/bizFormPresenter';
import {FormMode} from '@src/bizForm/formMode';
import {RouteUtils} from '@src/router/routeUtils';

export const EditAction = {
  key: 'edit',
  label: '编辑',
  options: {
    onClick: (presenter: BizFormPresenter, data: any) => {
      RouteUtils.toForm({
        entityName: presenter.entityName,
        mode: FormMode.Edit,
        id: presenter.initFormData.id,
      });
    }
  }
};

import axios from "axios";

export class Downloadutils {
    static download (src: string, fileName?: string) {

        axios.get(src, {responseType: "blob"})
        .then((response) => {
            const filenameArr = src.split("/");
            let blob = new Blob([response.data], { type: response.data.type })
            let url = URL.createObjectURL(blob)
            const node = document.createElement("a")
            node.href = url
            node.download = filenameArr[filenameArr.length - 1];
            node.style.display = "none";
            document.body.appendChild(node);
            node.click();
            document.body.removeChild(node);
        })
    }
}
import {BizFormPresenter} from '@src/bizForm/bizFormPresenter';
import {BizListPresenter} from '@src/bizList/bizListPresenter';
import {LoadingStatus} from '@src/components/LoadingContainer';
import {IMetadata} from '@src/interfaces/metadata';
import {ITemplate} from '@src/interfaces/template';
import {observable} from 'mobx';
import {BaseDataLoader} from '../baseDataLoader/dataloader';
import {QueryBuilder} from '../queryBuilder';
import {QueryBuilderHelper} from '../queryBuilder/queryBuilderHelper';

/**
 * Dataloader graphql和queryBuilder的基础上封装了查询的逻辑，
 * 将一部分逻辑抽离出来dataloader自己管理；
 * 1. 自动管理分页问题
 * 2. 管理查询状态
 */
export interface IDataloaderQueryOptions {
  queryFields: string[];
  queryStr?: string;
  id?: string;
  queryCondition?: string[];
  pageSize?: number;
  pageNo?: number;
}

export interface IDataloaderOptions {
  entityName: string;
  queryStr?: string;
  logicPath?: string;
  queryFields?: Array<string>;
  // [key: string]: any;
  // presenter: BizFormPresenter | BizListPresenter;
}

/**
 * 提供dataLoader的基础逻辑，必须作为父类使用
 */
export class DataLoader {
  protected queryOptions: IDataloaderQueryOptions = {
    queryFields: [],
    queryCondition: [],
    queryStr: '',
    pageNo: 1,
    pageSize: 10
  };

  public queryBuilder: QueryBuilder;

  public data: any;

  @observable
  public loadingStatus = LoadingStatus.None;

  constructor (protected options: IDataloaderOptions) {}

  protected initQueryBuilder () {
    this.queryBuilder = QueryBuilderHelper.create({
      entityName: this.options.entityName,
      queryOptions: this.queryOptions
    });
  }

  public query (): void {}

  public clear () {
    // this.data.length = 0;
  }
}

import {metadata} from '@src/metadata/metadata';

// 后端模型type判断
export class FieldPathUtils {
  // 获取path中末尾节点在对应实体中的模型数据
  public static getPathInfo (entityName: string, path: string) {
    const pathArr = path.split('.');
    let entity = metadata.getEntity(entityName);
    let pathInfo = null;
    while (pathArr.length) {
      const pathItem = pathArr.shift();
      const field = entity.getField(pathItem);
      if (field) {
        // 普通字段直接结束
        if (!field.isRefer && !field.isDetail) {
          pathInfo = field;
          break;
        }
        // 如果当前节点是末尾节点，直接返回，即使是refer也返回。
        if (!pathArr.length) {
          return field;
        }
        // 如果当前节点是refer，则继续往下找
        if (field.isRefer) {
          entity = metadata.getEntity(field.referType);
        } else {
          break;
        }
      } else {
        break;
      }
    }

    return pathInfo;
  }
}

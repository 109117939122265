import {MSTFormField} from '@src/MST-Form/MSTFormField';
import * as React from 'react';
import {BizFormPresenter} from '../bizFormPresenter';
import {BizForm} from '../core/model/bizForm';
import {BizFormField} from '../core/model/bizFormField';
import {FormMode} from '../formMode';
import {BizFormItemProps} from '../interfaces';
import {Carry} from './Carry';
import {Checkbox} from './Checkbox';
import CodeEditor from './CodeEditorBase';
import {Count} from './Count';
import {DatePicker} from './DatePicker';
import {DatetimePicker} from './DatetimePicker';
import EnumSelect from './EnumSelect';
import {Input} from './Input';
import {MarkdownEditor} from './MarkdownEditor';
import {Numeric} from './Numeric';
import ReferSelect from './ReferSelect';
import TagsEditor from './TagsEditor';
import {Textarea} from './Textarea';
import {TimePicker} from './TimePicker';
import { MyUpload } from './Upload';

export interface matchComponentProps {
  model: BizFormField;
  field: MSTFormField;
  mode: FormMode;
  presenter?: BizFormPresenter;
}

export const componentMapping: any = {
  Text: Input,
  ReferSelect: ReferSelect,
  Date: DatePicker,
  Time: TimePicker,
  Datetime: DatetimePicker,
  Checkbox: Checkbox,
  Textarea: Textarea,
  Numeric: Numeric,
  Count: Count,
  Carry: Carry,
  Markdown: MarkdownEditor,
  EnumSelect: EnumSelect,
  TagsEditor: TagsEditor,
  Image: MyUpload,
  Code: CodeEditor,
};

// 处理组件的props
function proccessProps (itemProps: matchComponentProps) {
  const {field, mode, model, presenter} = itemProps;
  const props: any = {
    disabled: field ? field.disabled : model.disabled,
    mode, // 组件的模式
    presenter,
    model,
    field
  };

  if (model.bizType === 'enum') {
    props.enumName = model.entityField?.enumType
  }

  return props;
}

export function matchComponent (itemProps: matchComponentProps) {
  const {
    model: {component, isRefer, isCarry}
  } = itemProps;
  let Comp = componentMapping[ component ];
  if (isCarry) {
    Comp = componentMapping[ 'Carry' ];
  } else if (isRefer) {
    Comp = componentMapping[ 'ReferSelect' ];
  }
  if (!Comp) {
    Comp = componentMapping[ 'Text' ];
  }
  const props = proccessProps(itemProps);
  return <Comp {...props} />;
}

import { ENUM_Entity_Workflow } from "@packages/gen/constants/enums/entityName"
import EN_Workflow from "@packages/gen/types/entities/Workflow"
import { FormMode } from "@src/bizForm/formMode"
import { RouteUtils } from "@src/router/routeUtils"

export const RowView = {
    key: "view",
    label: "查看",
    options: {
        onClick: (_: any, data?: EN_Workflow) => {
            RouteUtils.to(`/${ENUM_Entity_Workflow}/${FormMode.View}/${data?.id}`)
        }
    }
}

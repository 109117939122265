import {BIZ_AUTH} from '@src/common/constants/token';
import {RouteUtils} from '@src/router/routeUtils';
import localforage from 'localforage';

class Operator {
  //  是否初始化完成
  public isInitilized = false;
  // operator的所有数据，暂时使用any，后面使用脚本生成entity的ts文件后改回去
  public data: any;

  public init (data: any) {
    this.data = data;
  }
  public avatar () {
    return this.data.avatar;
  }
  public getID(): string {
    return this.data.id;
  }
  // 登出
  public logout () {
    const r_url = `${location.pathname}${location.search}`;
    localforage.removeItem(BIZ_AUTH);
    RouteUtils.to(`/login?r_url=${encodeURIComponent(r_url)}`);
  }
}

const OperatorStore = new Operator();

export default OperatorStore;

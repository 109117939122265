import {ENUM_Entity_Workflow} from "@packages/gen/constants/enums/entityName";
import {FormMode} from "@src/bizForm/formMode";
import {isProd} from "@src/utils";
import * as React from "react";
import {Route} from "react-router-dom";
const Template = React.lazy(() => import("@src/pages/devtool/bizForm/form/index"));
const TemplateList = React.lazy(() => import("@src/pages/devtool/bizForm/list"));
const QuerySchema = React.lazy(() => import("@src/pages/devtool/querySchema/form/index"));
const QuerySchemaList = React.lazy(() => import("@src/pages/devtool/querySchema/list"));
const Settings = React.lazy(() => import("@src/pages/settings"));
const Workflow = React.lazy(() => import("@src/pages/workflow/form"));
const WorkflowView = React.lazy(() => import("@src/pages/workflow/view"));

const BaseRoutes = [
  // settings
  <Route path="/settings" component={Settings}></Route>,
  // workflow
  <Route exact path={`/${ENUM_Entity_Workflow}/${FormMode.Edit}/:formId`} component={Workflow}></Route>,
  <Route exact path={`/${ENUM_Entity_Workflow}/${FormMode.Create}`} component={Workflow}></Route>,
  <Route exact path={`/${ENUM_Entity_Workflow}/${FormMode.View}/:formId`} component={WorkflowView}></Route>,
];

if (!isProd) {
  BaseRoutes.unshift(
    ...[
      <Route
        exact
        key="/devtool/querySchema/:entityName/:mode"
        path="/devtool/querySchema/:entityName/:mode"
        component={QuerySchema}
      ></Route>,
      <Route exact key="/devtool/querySchema" path="/devtool/querySchema" component={QuerySchemaList}></Route>,
      <Route
        exact
        key="/devtool/form/:entityName/:mode"
        path="/devtool/form/:entityName/:mode"
        component={Template}
      ></Route>,
      <Route exact key="/devtool/form" path="/devtool/form" component={TemplateList}></Route>,
    ],
  );
}
export default BaseRoutes;

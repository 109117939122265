import LoadingContainer, {LoadingStatus} from '@src/components/LoadingContainer';
import * as React from 'react';
import {BizFormPresenter} from './bizFormPresenter';
import {PresenterProvider} from './context/bizFormContext';
import {FormMode} from './formMode';
import {LifecycleEvent} from './lifecycleEvent';
import {ModuleNames} from './modules/interface';
import {BizFormBody} from './page/bizFormBody';

interface IBizForm {
  presenter?: BizFormPresenter;
  mode: FormMode;
  id?: string;
}

export class BizForm extends React.Component<IBizForm, any> {
  constructor (props: IBizForm) {
    super(props);
    this.state = {
      loadingStatus: LoadingStatus.Loading
    };
    // this.presenter.mode = this.props.mode;
    this.presenter.id = this.props.id;
  }

  componentDidMount () {
    // 获取metadata、tempalte数据， 以后修改
    // this.getInitData();
    this.init();
  }

  componentWillUnmount () {
    this.presenter.destructor();
  }

  // 初始化数据
  private init = () => {
    this.presenter.initData();
    this.presenter.getModule(ModuleNames.EventModule).on(LifecycleEvent.onFormInitialized, this.onFormInitialized);
    this.presenter.getModule(ModuleNames.EventModule).on(LifecycleEvent.reloadBizForm, this.reload);
  };

  // 重新加载数据
  public reload = () => {
    this.setState({
      loadingStatus: LoadingStatus.Loading
    });
    this.init();
  };

  onFormInitialized = async () => {
    try {
      this.setState({
        loadingStatus: LoadingStatus.Complete
      });
    } catch (err) {
      console.error(err);
      this.setState({
        loadingStatus: LoadingStatus.Failed
      });
    }
  };

  render () {
    return (
      <PresenterProvider value={this.presenter}>
        <LoadingContainer status={this.state.loadingStatus}>{this.renderContent}</LoadingContainer>
      </PresenterProvider>
    );
  }

  renderContent = () => {
    if (this.state.loadingStatus === LoadingStatus.Complete) {
      return <BizFormBody presenter={this.presenter} />;
    }
    return null;
  };

  get presenter () {
    return this.props.presenter;
  }
}

import {fetchFormAction} from '@src/http/formData';

export default class FormUtils {
  // 将字符串首字母转为小写
  static FormAction (entityName: string, action: string, data: any) {
    return fetchFormAction({
      entityName,
      action,
      data
    });
  }

  static async FormActionDelete (entityName: string, id: string) {
    const result = await FormUtils.FormAction(entityName, 'delete', id);
    return result;
  }
}

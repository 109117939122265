import {BizListPresenter} from '@src/bizList/bizListPresenter';
import _get from 'lodash/get';
import {RouteUtils} from '@src/router/routeUtils';
/**
 * 列表行使用
 */
export const GotoListAction = {
  key: 'gotoList',
  label: '返回列表',
  options: {
    onClick: (presenter: BizListPresenter, data?: any) => {
      RouteUtils.toList({
        entityName: presenter.entityName
      });
    }
  }
};

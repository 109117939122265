import * as React from 'react';
import {Checkbox as AntCheckbox} from 'antd';
import {IBizFormComponentOProps} from '../interface';
import {FormMode} from '@src/bizForm/formMode';

export interface ICheckboxViewer {
  value: boolean;
  checked?: boolean;
}

export const CheckboxViewer = (props: ICheckboxViewer) => {
  return <AntCheckbox {...props} disabled checked={props.value} />;
};

export class StringUtils {
  // 将字符串首字母转为小写
  static lowerCapital (str: string) {
    if (typeof str !== 'string') {
      return str;
    }

    return str[ 0 ].toLowerCase() + str.substring(1);
  }

  static upperCapital (str: string) {
    if (typeof str !== 'string') {
      return str;
    }

    return str[ 0 ].toUpperCase() + str.substring(1);
  }
}

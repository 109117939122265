import {PieChartOutlined} from "@ant-design/icons";
import IconFont from "@src/components/Iconfont";
import {isProd} from "@src/utils";
import React from "react";

export interface INavConfig {
  key: string;
  path: string;
  label: string;
  icon: React.ReactNode;
  children?: Array<INavConfig>;
}

export const configList: Array<INavConfig> = [
  // {
  //   key: "/Workflow-list",
  //   path: "/Workflow-list",
  //   label: "教程列表",
  //   icon: <IconFont type="icon-workflow" />,
  // },
  {
    key: "/Article-list",
    path: "/Article-list",
    label: "文章列表",
    icon: <IconFont type="icon-article" />,
  },
  {
    key: "/TinyCode-list",
    path: "/TinyCode-list",
    label: "在线代码",
    icon: <IconFont type="icon-article" />,
  },
  {
    key: "/Category-list",
    path: "/Category-list",
    label: "基础数据",
    icon: <IconFont type="icon-database" />,
  },
  {
    key: "/settings",
    path: "/settings",
    label: "设置",
    icon: <IconFont type="icon-setting" />,
  },
  ...(!isProd
    ? [
      {
        key: "/Operator-list",
        path: "/Operator-list",
        label: "用户列表",
        icon: <IconFont type="icon-workflow" />,
      },
        {
          key: "/File-list",
          path: "/File-list",
          label: "文件列表",
          icon: <PieChartOutlined />,
        },
        {
          key: "/Email-list",
          path: "/Email-list",
          label: "邮件列表",
          icon: <PieChartOutlined />,
        },
        {
          key: "/Template-list",
          path: "/Template-list",
          label: "模版列表",
          icon: <PieChartOutlined />,
        },
        {
          key: "/devtool/form",
          path: "/devtool/form",
          label: "开发工具",
          icon: <PieChartOutlined />,
        },
        {
          key: "/devtool/querySchema",
          path: "/devtool/querySchema",
          label: "列表方案",
          icon: <PieChartOutlined />,
        },
      ]
    : []),
];

export const Nav_Default = "/Workflow-list";

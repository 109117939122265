import moment from 'moment';
import {BetweenWrapper} from '../BetweenWrapper';
import {DatePicker} from '../DatePicker';
import {IBizFormComponentOProps} from '../interface';

export interface IDatePickerBetween extends IBizFormComponentOProps {
  value: Array<number>;
}
/**
 *
 * @param props 日期组件
 * @returns
 */
export const DatePickerBetween = (props: IDatePickerBetween) => {
  const value = Array.isArray(props.value) ? props.value : [];

  const onChange = (index: number, val: number) => {
    value[ index ] = val;
    if (val && index === 0) {
      // 开始时间
      value[ index ] = moment(value[ index ]).startOf('d').valueOf();
    }
    if (val && index === 1) {
      // 天开始时间
      value[ index ] = moment(value[ index ]).endOf('d').valueOf();
    }
    props?.onChange(value);
  };

  return <BetweenWrapper {...props} value={value} onChange={onChange} component={DatePicker} />;
};

// eslint-disable-next-line no-use-before-define
import React, {useCallback, useEffect, useState} from "react";
import {Layout, Menu} from "antd";
import {configList, Nav_Default} from "./config";
import styles from "./style.module.scss";
import {globalHistory, RouteUtils} from "@src/router/routeUtils";
const {Sider} = Layout;
const {SubMenu} = Menu;

export function Nav () {
  const [selectedKey, setSelectedKey] = useState([]);
  const [collapsed, setCollapsed] = useState(false);

  const historyLientener = useCallback(() => {
    globalHistory.listen((data: any) => {
      const selectedKeys = [data.pathname];
      const pathArr = data.pathname.split("/") || [];
      if (pathArr.length > 2) {
        selectedKeys.unshift("/" + pathArr[1]);
      }
      setSelectedKey(selectedKeys);
    });
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      window.location.pathname = Nav_Default;
    } else {
      setSelectedKey([window.location.pathname]);
    }
    historyLientener();
  }, []);

  const onSelect = (options: any) => {
    const {selectedKeys} = options;
    setSelectedKey(selectedKeys);
  };
  const onClick = (props: any) => {
    if (props.key === location.pathname) {
      return;
    }
    RouteUtils.to(props.keyPath.join(""));
  };
  return (
    <Sider className={styles["layout-sider"]} collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
      <div className="logo" />
      <Menu selectedKeys={selectedKey} mode="vertical" onClick={onClick}>
        {configList.map((item) =>
          item.children ? (
            <SubMenu key={item.key} icon={item.icon} title={item.label}>
              {item.children.map((subItem) => (
                <Menu.Item key={subItem.key} icon={subItem.icon}>
                  {/* <NavLink to={subItem.path}>{subItem.label}</NavLink> */}
                  <span>{subItem.label}</span>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={item.key} icon={item.icon}>
              {/* <NavLink to={item.path}>{item.label}</NavLink> */}
              <span>{item.label}</span>
            </Menu.Item>
          ),
        )}
      </Menu>
    </Sider>
  );
}

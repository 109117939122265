import EN_Category from '@packages/gen/types/entities/Category';
import {BizListPresenter} from '@src/bizList/bizListPresenter';

export class CategoryListPresenter<T = EN_Category> extends BizListPresenter<T> {
  // public listBizConfig (config: BizListConfig<T>): BizListConfig<T> {
  //   config.column = {
  //     name: {
  //       cellRender: (val) => {
  //         return <span style={{color: 'red'}}>{val}</span>;
  //       }
  //     }
  //   };

  //   config.listRowActionManager.use((actions: Array<IBizAction>, data: any): Array<IBizAction> => {
  //     // if (data.id === 'db56a548-b6f8-4bd4-8886-051dcde9858b') {
  //     //   return [];
  //     // }
  //     return actions;
  //   });
  //   return config;
  // }
}

import {Button, Input, Modal, Table} from "antd";
import React, {memo, useCallback} from "react";
import {AdvanceReferModalProps} from "./types";
import "./style.scss";
import { UndoOutlined } from "@ant-design/icons";

const AdvanceReferModal = (props: AdvanceReferModalProps): JSX.Element => {
  const {visible, onCancel, onOk, columns = [], dataSource = [], onSearch, placeholder} = props;

  const handleRowClick = useCallback((record, event) => {
    onOk?.(record, event);
  }, []);

  return (
    <Modal width={700} visible={visible} onCancel={onCancel} wrapClassName="advanceRefer">
      <div className={"advanceRefer-header"}>
        <Input.Search allowClear onSearch={onSearch} placeholder={placeholder ?? "请输入"} />
        <Button icon={<UndoOutlined />} className={"advanceRefer-header-refresh"}>刷新</Button>
      </div>

      <Table
        columns={columns}
        dataSource={columns}
        onRow={(record) => {
          return {
            onClick: (event) => {
              handleRowClick(record, event);
            }, // 点击行
            //   onDoubleClick: (event) => {},
            //   onContextMenu: (event) => {},
            //   onMouseEnter: (event) => {}, // 鼠标移入行
            //   onMouseLeave: (event) => {},
          };
        }}
      />
    </Modal>
  );
};

export default memo(AdvanceReferModal);

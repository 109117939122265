import { IQuerySchema, IQuerySchemaItem } from "@src/interfaces/queryScheme";
import { makeAutoObservable } from "mobx";
import { IQuerySolution, QuerySolution } from "./querySolution";

export interface IQuerySolutionHelper {
  querySchema: IQuerySchema;
}

export class QuerySolutionHelper {
  static createQuerySchema(params: IQuerySolution) {
    // 设置默认的valueTypes
    const  defaultValueTypes = params.querySchema.items.reduce(
      (prev: any, cur: IQuerySchemaItem) => {
        prev[cur.path] = cur.valueType;
        return prev;
      },
      {} as any
    );
    params.options.valueTypes = {
      ...defaultValueTypes,
      ...(params.options.valueTypes || {})
    }
    return makeAutoObservable(new QuerySolution(params));
  }
}

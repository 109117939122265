import {FormActionBar} from '@src/bizAction/ActionBar/FormActionBar';
import {BizFormPresenter} from '@src/bizForm/bizFormPresenter';
import {FormMode} from '@src/bizForm/formMode';
import {metadata} from '@src/metadata/metadata';
import * as React from 'react';
import style from './style.module.scss';
/**
 * FormHeader用于管理表单头部的功能，包括：
 * 水印、功能按钮
 */

interface IFormHeader {
  presenter: BizFormPresenter;
}

export class FormHeader extends React.Component<IFormHeader> {
  render () {
    const {presenter} = this.props;

    if (presenter.mode !== FormMode.View) {
      return null;
    }

    return (
      <div className={style[ 'form-header' ]}>
        <div>
          <h3 className={style[ 'form-header-title' ]}>{metadata.getEntity(presenter.entityName).title}</h3>
        </div>
        <div className={style[ 'form-header-actions' ]}>
          {/* <FormActions presenter={presenter} actions={actions}/> */}
          <FormActionBar presenter={presenter} />
        </div>
      </div>
    );
  }

  private renderActions = () => {};
}

import React from 'react';

import {Tag, Tooltip} from 'antd';
import {TagsViewerProps} from './types';
import "./style.scss";

export default class TagsViewer extends React.Component<TagsViewerProps> {

  getTags = (): Array<string> => {
    return this.props.value || [];
  };

  render () {
    const tags = this.getTags();
    return (
      <>
        {tags.map((tag) => {
          const isLongTag = tag.length > 20;

          const tagElem = (
            <Tag className="tagsEditor-tag" key={tag}>
              <span
              >
                {isLongTag ? `${ tag.slice(0, 20) }...` : tag}
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
      </>
    );
  }
}
